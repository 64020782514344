<template>
  <main v-if="!isEcommerceLoading && !isCategoriesLoading && !isCollectionsLoading && !isProductsLoading">
    <PinkThemeHeader />
    <PinkThemeNav />
    <slot />
    <FloatingButton />
    <PinkThemeFooter />
  </main>
  <Loader v-else />
</template>

<script setup lang="ts">
import PinkThemeHeader from './components/PinkThemeHeader.vue';
import PinkThemeNav from './components/PinkThemeNav.vue';
import PinkThemeFooter from './components/PinkThemeFooter.vue';
import FloatingButton from './components/FloatingButton.vue';
import Loader from '@/components/loaders/Loader.vue';
import { onMounted } from 'vue';
import { useCategory } from '@/composables/useCategory';
import { useCollection } from '@/composables/useCollection';
import { useProduct } from '@/composables/useProduct';
import { useEcommerce } from '@/composables/useEcommerce';
import { useCache } from '@/composables/useCache';
import router from '@/router';

const { needUpdate } = useCache()
const { loadEcommerce, isEcommerceLoading, ecommerce } = useEcommerce()
const { loadCategories, isCategoriesLoading } = useCategory()
const { loadCollections, isCollectionsLoading } = useCollection()
const { loadProducts, isProductsLoading } = useProduct()

onMounted(async () => {
  const lastUpdate = await needUpdate();
  const localLastUpdate = localStorage.getItem('lastUpdate');

  const isUpdated = localLastUpdate !== String(lastUpdate);

  if (isUpdated) {
    localStorage.setItem('lastUpdate', String(lastUpdate));
  }

  const shouldReload = isUpdated; // `true` para atualizar, `false` se já estiver sincronizado
  loadEcommerce(shouldReload);
  loadCategories(shouldReload);
  loadCollections(shouldReload);
  loadProducts(shouldReload);

  if (window.location.href.includes('hooshop') && ((localStorage.getItem('password') !== ecommerce.value.password) || !localStorage.getItem('password'))) {
    router.push({ name: 'password' })
  }
});
</script>

<style lang="scss">
@import './../../../../assets/css/main.css';

$primary-color: v-bind("ecommerce?.visual?.colorPrimary ?? 'rgb(253 61 87 / var(--tw-bg-opacity))'");
$menu-color: v-bind("ecommerce.visual?.colorMenuText");
$menu-color-hover: v-bind("ecommerce.visual?.colorMenuHover");

.text-menu {
  color: v-bind("ecommerce.visual?.colorMenuText")
}
.text-menu-hover, .hover\:text-menu-hover:hover {
  color: $menu-color-hover !important
}

.bg-primary { 
  background: $primary-color
}
.border-primary {
  border-color: $primary-color
}
.text-primary {
  color: $primary-color
}
.fill-primary {
  fill: $primary-color
}
.hover\:text-primary:hover {
  color: $primary-color
}
.simple-spinner .loader {
  border: 5px solid $primary-color
}
</style>