import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]

import { computed } from 'vue';
import { useCategory } from '@/composables/useCategory';
import { useCollection } from '@/composables/useCollection';
import { useEcommerce } from '@/composables/useEcommerce';
import { useMenu } from '@/composables/useMenu';
import { MenuTypeEnum } from '@/enums/ecommerce/menus/MenuTypeEnum';
import { Menu } from '@/interfaces/ecommerce/Menu';
import { useRoute } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'MenuLink',
  props: {
    menu: {},
    class: {}
  },
  setup(__props: any) {

const props = __props;
const { ecommerce } = useEcommerce();
const { categories } = useCategory();
const { collections } = useCollection();
const { redirectMenu } = useMenu();
const route = useRoute()

const isActive = computed(() => {
  if (props.menu.type === MenuTypeEnum.PAGE) {
    return ecommerce.value.pages?.find((page) => page.id === props.menu.typeId)?.slug === route.path.slice(1);
  }

  if (props.menu.type === MenuTypeEnum.CATEGORY) {
    return (
      route.path.includes('categories') &&
      categories.value?.find((c) => c.id === props.menu.typeId)?.slug === route.params.slug
    );
  }

  if (props.menu.type === MenuTypeEnum.COLLECTION) {
    return (
      route.path.includes('collections') &&
      collections.value?.find((c) => c.id === props.menu.typeId)?.slug === route.params.slug
    );
  }

  return false;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("a", {
    target: "_blank",
    class: _normalizeClass({
      'text-menu-hover': isActive.value,
      [props.class]: true
    }),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(redirectMenu)($event, props.menu))),
    href: props.menu.href,
    key: props.menu.id
  }, _toDisplayString(props.menu.title), 11, _hoisted_1))
}
}

})