import { useCssVars as _useCssVars, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeStyle as _normalizeStyle, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "col-span-2 grid grid-cols-2 md:grid-cols-4 gap-[48px] md:gap-4" }
const _hoisted_2 = { class: "grid sm:grid-cols-1 gap-4 md:gap-8" }
const _hoisted_3 = { class: "space-y-4" }
const _hoisted_4 = {
  key: 0,
  class: "text-2xl font-medium text-gray-800 uppercase"
}
const _hoisted_5 = {
  key: 1,
  class: "text-gray-500 text-sm"
}
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  key: 0,
  class: "col-span-1"
}
const _hoisted_8 = { class: "mt-4 space-y-4" }
const _hoisted_9 = {
  key: 1,
  class: "col-span-1"
}
const _hoisted_10 = { class: "mt-4 space-y-4" }
const _hoisted_11 = {
  key: 2,
  class: "col-span-1"
}
const _hoisted_12 = { class: "mt-4 space-y-4" }
const _hoisted_13 = {
  key: 0,
  href: "#",
  class: "text-base text-gray-500 hover:text-gray-900 block overflow-hidden"
}
const _hoisted_14 = {
  key: 1,
  href: "#",
  class: "text-base text-gray-500 hover:text-gray-900 block"
}
const _hoisted_15 = {
  key: 2,
  href: "#",
  class: "text-base text-gray-500 hover:text-gray-900 block"
}
const _hoisted_16 = {
  key: 3,
  class: "col-span-1"
}
const _hoisted_17 = { class: "mt-4 space-y-4" }
const _hoisted_18 = {
  key: 0,
  href: "#",
  class: "text-base text-gray-500 hover:text-gray-900 block"
}
const _hoisted_19 = {
  key: 1,
  href: "#",
  class: "text-base text-gray-500 hover:text-gray-900 block"
}
const _hoisted_20 = {
  key: 2,
  href: "#",
  class: "text-base text-gray-500 hover:text-gray-900 block"
}
const _hoisted_21 = {
  key: 3,
  href: "#",
  class: "text-base text-gray-500 hover:text-gray-900 block"
}
const _hoisted_22 = {
  key: 4,
  href: "#",
  class: "text-base text-gray-500 hover:text-gray-900 block"
}
const _hoisted_23 = { class: "container flex items-center justify-between" }

import { useEcommerce } from '@/composables/useEcommerce';
import { useCategory } from '@/composables/useCategory';
import { useCollection } from '@/composables/useCollection';
import { useFormat } from '@/composables/useFormat';


export default /*@__PURE__*/_defineComponent({
  __name: 'PinkThemeFooter',
  setup(__props) {

_useCssVars(_ctx => ({
  "657c9e8a": (_unref(ecommerce).visual?.colorFooterLink),
  "28dd357e": (_unref(ecommerce).visual?.colorFooterLinkHover),
  "b1474076": (_unref(ecommerce).visual?.footerLinkFontFamily),
  "6f7ceebc": (_unref(ecommerce).visual?.footerLinkFontSize),
  "76d5724e": (_unref(ecommerce).visual?.footerLinkFontWeight),
  "27908346": (_unref(ecommerce).visual?.colorFooterLinkTitle),
  "7ecc0dce": (_unref(ecommerce).visual?.footerLinkTitleFontFamily),
  "20fd66f6": (_unref(ecommerce).visual?.footerLinkTitleFontSize),
  "445a3fa6": (_unref(ecommerce).visual?.footerLinkTitleFontWeight)
}))

const { formatDocument } = useFormat()
const { ecommerce, logo } = useEcommerce()
const { categories } = useCategory()
const { collections } = useCollection()

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("footer", {
      class: "bg-white pt-16 pb-12 border-t border-gray-100 px-4 sm:px-0 max-w-full",
      style: _normalizeStyle({ backgroundColor: _unref(ecommerce).visual?.footerColor })
    }, [
      _createElementVNode("div", {
        class: "grid grid-cols-1 mx-auto",
        style: _normalizeStyle({ maxWidth: _unref(ecommerce).visual?.footerWidth })
      }, [
        _cache[1] || (_cache[1] = _createStaticVNode("<div class=\"col-span-1 space-y-4\"><div class=\"flex space-x-5\"><a href=\"#\" class=\"text-gray-400 hover:text-gray-500\"><i class=\"fa-brands fa-facebook-square\"></i></a><a href=\"#\" class=\"text-gray-400 hover:text-gray-500\"><i class=\"fa-brands fa-instagram-square\"></i></a><a href=\"#\" class=\"text-gray-400 hover:text-gray-500\"><i class=\"fa-brands fa-twitter-square\"></i></a><a href=\"#\" class=\"text-gray-400 hover:text-gray-500\"><i class=\"fa-brands fa-github-square\"></i></a></div></div>", 1)),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_3, [
                (_unref(ecommerce).visual?.footerTitle)
                  ? (_openBlock(), _createElementBlock("h4", _hoisted_4, _toDisplayString(_unref(ecommerce).visual?.footerTitle), 1))
                  : _createCommentVNode("", true),
                (_unref(ecommerce).visual?.footerDescription)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_unref(ecommerce).visual?.footerDescription), 1))
                  : _createCommentVNode("", true),
                _createElementVNode("img", {
                  src: _unref(logo) ? _unref(logo)?.source : 'assets/images/no-image.png',
                  alt: "logo",
                  class: "max-w-[128px] max-h-[128px] w-full"
                }, null, 8, _hoisted_6)
              ])
            ])
          ]),
          (_unref(ecommerce).visual?.displayFooterCategories)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _cache[0] || (_cache[0] = _createElementVNode("h3", { class: "text-sm font-semibold text-gray-400 uppercase tracking-wider" }, " Categorias ", -1)),
                _createElementVNode("div", _hoisted_8, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(categories), (category) => {
                    return (_openBlock(), _createBlock(_component_RouterLink, {
                      to: { name: 'shop', query: { categoryId: category.id } },
                      class: "text-base text-gray-500 hover:text-gray-900 block"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(category.title), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"]))
                  }), 256))
                ])
              ]))
            : _createCommentVNode("", true),
          (_unref(ecommerce).visual?.displayFooterCollections)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createElementVNode("h3", {
                  class: "text-sm font-semibold text-gray-400 uppercase tracking-wider",
                  style: _normalizeStyle({ color: _unref(ecommerce).visual?.colorFooterLinkTitle })
                }, " Coleções ", 4),
                _createElementVNode("div", _hoisted_10, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(collections), (collection) => {
                    return (_openBlock(), _createBlock(_component_RouterLink, {
                      to: { name: 'shop', query: { categoryId: collection.id } },
                      class: "text-base text-gray-500 hover:text-gray-900 block"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(collection.title), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"]))
                  }), 256))
                ])
              ]))
            : _createCommentVNode("", true),
          (_unref(ecommerce).visual?.displayFooterContact)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createElementVNode("h3", {
                  class: "text-sm font-semibold text-gray-400 uppercase tracking-wider",
                  style: _normalizeStyle({ color: _unref(ecommerce).visual?.colorFooterLinkTitle })
                }, " Contato ", 4),
                _createElementVNode("div", _hoisted_12, [
                  (_unref(ecommerce).email)
                    ? (_openBlock(), _createElementBlock("a", _hoisted_13, _toDisplayString(_unref(ecommerce).email), 1))
                    : _createCommentVNode("", true),
                  (_unref(ecommerce).phoneNumber)
                    ? (_openBlock(), _createElementBlock("a", _hoisted_14, _toDisplayString(_unref(ecommerce).phoneNumber), 1))
                    : _createCommentVNode("", true),
                  (_unref(ecommerce).whatsapp)
                    ? (_openBlock(), _createElementBlock("a", _hoisted_15, _toDisplayString(_unref(ecommerce).whatsapp), 1))
                    : _createCommentVNode("", true)
                ])
              ]))
            : _createCommentVNode("", true),
          (_unref(ecommerce).visual?.displayFooterSocialMedia)
            ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                _createElementVNode("h3", {
                  class: "text-sm font-semibold text-gray-400 uppercase tracking-wider",
                  style: _normalizeStyle({ color: _unref(ecommerce).visual?.colorFooterLinkTitle })
                }, " Redes sociais ", 4),
                _createElementVNode("div", _hoisted_17, [
                  (_unref(ecommerce).twitter)
                    ? (_openBlock(), _createElementBlock("a", _hoisted_18, _toDisplayString(_unref(ecommerce).twitter), 1))
                    : _createCommentVNode("", true),
                  (_unref(ecommerce).instagram)
                    ? (_openBlock(), _createElementBlock("a", _hoisted_19, _toDisplayString(_unref(ecommerce).instagram), 1))
                    : _createCommentVNode("", true),
                  (_unref(ecommerce).facebook)
                    ? (_openBlock(), _createElementBlock("a", _hoisted_20, _toDisplayString(_unref(ecommerce).facebook), 1))
                    : _createCommentVNode("", true),
                  (_unref(ecommerce).youtube)
                    ? (_openBlock(), _createElementBlock("a", _hoisted_21, _toDisplayString(_unref(ecommerce).youtube), 1))
                    : _createCommentVNode("", true),
                  (_unref(ecommerce).linkedin)
                    ? (_openBlock(), _createElementBlock("a", _hoisted_22, _toDisplayString(_unref(ecommerce).linkedin), 1))
                    : _createCommentVNode("", true)
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ], 4)
    ], 4),
    _createElementVNode("div", {
      class: "bg-gray-800 py-4",
      style: _normalizeStyle({ backgroundColor: _unref(ecommerce).visual?.colorFooterBottom })
    }, [
      _createElementVNode("div", _hoisted_23, [
        _createElementVNode("p", {
          style: _normalizeStyle({ color: _unref(ecommerce).visual?.colorFooterLink })
        }, "Copyright © " + _toDisplayString(_unref(ecommerce).title) + " - " + _toDisplayString(_unref(formatDocument)(_unref(ecommerce).document)) + " - " + _toDisplayString(new Date().getFullYear()) + ". Todos os direitos reservados.", 5),
        _cache[2] || (_cache[2] = _createStaticVNode("<div class=\"flex gap-2 items-center flex-wrap\"><img src=\"/assets/images/payment-methods/pix.png\" alt=\"methods\" class=\"h-5\"><img src=\"/assets/images/payment-methods/boleto.jpeg\" alt=\"methods\" class=\"h-5\"><img src=\"/assets/images/payment-methods/bandeiras/elo.png\" alt=\"methods\" class=\"h-5\"><img src=\"/assets/images/payment-methods/bandeiras/hipercard.svg\" alt=\"methods\" class=\"h-5\"><img src=\"/assets/images/payment-methods/bandeiras/master.svg\" alt=\"methods\" class=\"h-5\"><img src=\"/assets/images/payment-methods/bandeiras/visa.svg\" alt=\"methods\" class=\"h-5\"></div>", 1))
      ])
    ], 4)
  ], 64))
}
}

})