<template>
  <fieldset class="rate" :disabled="disabled">
    <input
      type="radio"
      :id="id + '10'"
      :name="name"
      value="10"
      :checked="currentRating === 5"
      @change="setRating(5)"
      :disabled="disabled"
    /><label :for="id + '10'" title="5 estrelas"></label>

    <input
      type="radio"
      :id="id + '9'"
      :name="name"
      value="9"
      :checked="currentRating === 4.5"
      @change="setRating(4.5)"
      :disabled="disabled"
    /><label class="half" :for="id + '9'" title="4 1/2 estrelas"></label>

    <input
      type="radio"
      :id="id + '8'"
      :name="name"
      value="8"
      :checked="currentRating === 4"
      @change="setRating(4)"
      :disabled="disabled"
    /><label :for="id + '8'" title="4 estrelas"></label>

    <input
      type="radio"
      :id="id + '7'"
      :name="name"
      value="7"
      :checked="currentRating === 3.5"
      @change="setRating(3.5)"
      :disabled="disabled"
    /><label class="half" :for="id + '7'" title="3 1/2 estrelas"></label>

    <input
      type="radio"
      :id="id + '6'"
      :name="name"
      value="6"
      :checked="currentRating === 3"
      @change="setRating(3)"
      :disabled="disabled"
    /><label :for="id + '6'" title="3 estrelas"></label>

    <input
      type="radio"
      :id="id + '5'"
      :name="name"
      value="5"
      :checked="currentRating === 2.5"
      @change="setRating(2.5)"
      :disabled="disabled"
    /><label class="half" :for="id + '5'" title="2 1/2 estrelas"></label>

    <input
      type="radio"
      :id="id + '4'"
      :name="name"
      value="4"
      :checked="currentRating === 2"
      @change="setRating(2)"
      :disabled="disabled"
    /><label :for="id + '4'" title="2 estrelas"></label>

    <input
      type="radio"
      :id="id + '3'"
      :name="name"
      value="3"
      :checked="currentRating === 1.5"
      @change="setRating(1.5)"
      :disabled="disabled"
    /><label class="half" :for="id + '3'" title="1 1/2 estrelas"></label>

    <input
      type="radio"
      :id="id + '2'"
      :name="name"
      value="2"
      :checked="currentRating === 1"
      @change="setRating(1)"
      :disabled="disabled"
    /><label :for="id + '2'" title="1 estrela"></label>

    <input
      type="radio"
      :id="id + '1'"
      :name="name"
      value="1"
      :checked="currentRating === 0.5"
      @change="setRating(0.5)"
      :disabled="disabled"
    /><label class="half" :for="id + '1'" title="1/2 estrela"></label>
  </fieldset>
</template>

<script lang="ts" setup>
import { ref, defineEmits, watch } from "vue";

const props = defineProps<{ value?: number, name: string, id: string, disabled?: boolean }>()
const emits = defineEmits(["update:modelValue"]);
const currentRating = ref(props.value);

const setRating = (value: number) => {
  currentRating.value = value;
  emits("update:modelValue", value);
};

watch(() => props.value, (newValue) => {
  if (newValue !== currentRating.value) {
    currentRating.value = newValue;
  }
});
</script>

<style scoped>
/* Base setup */
@import url(//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css);
input:disabled ~ label:hover, 
input:disabled ~ label:hover ~ label, 
input:disabled + label:hover, 
input:disabled + label:hover ~ label {
  pointer-events: none;
  color: #e7e7e7cf; /* Mantém a cor neutra */
}

input:disabled label::before, fieldset:disabled {
  pointer-events: none;
}

/* Ratings widget */
.rate {
  display: inline-block;
  border: 0;
  width: 151px;
}
/* Hide radio */
.rate > input {
  display: none;
}
/* Order correctly by floating highest to the right */
.rate > label {
  float: right;
}
/* The star of the show */
.rate > label:before {
  display: inline-block;
  font-size: 1.6rem;
  padding: 0.3rem 0.2rem;
  margin: 0;
  cursor: pointer;
  font-family: FontAwesome;
  content: "\f005 "; /* full star */
}

/* Half star trick */
.rate .half:before {
  content: "\f089 "; /* half star no outline */
  position: absolute;
  padding-right: 0;
}
/* Click + hover color */
input:checked ~ label, /* color current and previous stars on checked */
label:hover, label:hover ~ label {
  color: #FFA800;
} /* color previous stars on hover */

/* Hover highlights */
input:checked + label:hover, input:checked ~ label:hover, /* highlight current and previous stars */
input:checked ~ label:hover ~ label, /* highlight previous selected stars for new rating */
label:hover ~ input:checked ~ label /* highlight previous selected stars */ {
  color: #FFA800;
}

input + label /* highlight previous selected stars */ {
  color: #e7e7e7cf;
}
</style>
