import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

import { useEcommerce } from '@/composables/useEcommerce';
import router from '@/router';
import { computed } from 'vue';
import PinkThemeLayout from '../layouts/PinkThemeLayout.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'PinkThemeDynamicView',
  props: {
    slug: {}
  },
  setup(__props: any) {

const props = __props
const { ecommerce } = useEcommerce()

const page = computed(() => {
  const page = ecommerce.value.pages?.find(page => page.slug === props.slug)

  if (!page) {
    router.push({ name: 'not-found' })
  }

  return page
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(PinkThemeLayout, null, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: "container pt-4 pb-16",
        innerHTML: page.value?.content
      }, null, 8, _hoisted_1)
    ]),
    _: 1
  }))
}
}

})