import { ref } from "vue"
import { toast } from 'vue3-toastify'

interface CartItem {
  uuid: string
  quantity: number
}

const cart = ref<CartItem[]>(JSON.parse(localStorage.getItem('cart') ?? '[]'))
const isAddingProductIntoCart = ref(false)


export function useCart() {
  function addIntoCart(uuid: string, quantity: number) {
    const itemExists = cart.value.findIndex((i) => i.uuid === uuid)
    isAddingProductIntoCart.value = true

    setTimeout(() => {
      if (itemExists !== -1) {
        cart.value[itemExists].quantity += quantity
      } else {
        cart.value.push({
          uuid,
          quantity
        })
      }

      localStorage.setItem('cart', JSON.stringify(cart.value))
      isAddingProductIntoCart.value = false
      quantity > 0 ? toast.success('Produto adicionado ao carrinho') : ''
    }, 400)
  }

  function removeFromCart(uuid: string) {
    cart.value = cart.value.filter((item) => item.uuid !== uuid)

    localStorage.setItem('cart', JSON.stringify(cart.value))
  }

  function clearCart() {
    localStorage.removeItem('cart')
    cart.value = []
  }

  return  {
    cart,
    addIntoCart,
    removeFromCart,
    isAddingProductIntoCart,
    clearCart
  }
}