<template>
  <!-- footer -->
  <footer class="bg-white pt-16 pb-12 border-t border-gray-100 px-4 sm:px-0 max-w-full" :style="{ backgroundColor: ecommerce.visual?.footerColor }">
    <div class="grid grid-cols-1 mx-auto" :style="{ maxWidth: ecommerce.visual?.footerWidth }">
      <div class="col-span-1 space-y-4">
        <!-- <div class="mr-2">
          <div class="mb-12">
            <h4 v-if="ecommerce.visual?.footerTitle" class="text-2xl font-medium text-gray-800 uppercase">
              {{ ecommerce.visual?.footerTitle }}
            </h4>
            <p v-if="ecommerce.visual?.footerDescription" class="text-gray-500 text-sm">
              {{ ecommerce.visual?.footerDescription }}
            </p>
          </div>
          <p class="text-gray-500 text-sm">
            {{ ecommerce.address?.street }}, {{ ecommerce.address?.district }}, Número {{ ecommerce.address?.number }}, {{ ecommerce.address?.complement }}
          </p>
        </div> -->
        <div class="flex space-x-5">
          <a href="#" class="text-gray-400 hover:text-gray-500"
            ><i class="fa-brands fa-facebook-square"></i
          ></a>
          <a href="#" class="text-gray-400 hover:text-gray-500"
            ><i class="fa-brands fa-instagram-square"></i
          ></a>
          <a href="#" class="text-gray-400 hover:text-gray-500"
            ><i class="fa-brands fa-twitter-square"></i
          ></a>
          <a href="#" class="text-gray-400 hover:text-gray-500">
            <i class="fa-brands fa-github-square"></i>
          </a>
        </div>
      </div>

      <div class="col-span-2 grid grid-cols-2 md:grid-cols-4 gap-[48px] md:gap-4">
        <div class="grid sm:grid-cols-1 gap-4 md:gap-8">
          <div>
            <div class="space-y-4">
              <h4 v-if="ecommerce.visual?.footerTitle" class="text-2xl font-medium text-gray-800 uppercase">
                {{ ecommerce.visual?.footerTitle }}
              </h4>
              <p v-if="ecommerce.visual?.footerDescription" class="text-gray-500 text-sm">
                {{ ecommerce.visual?.footerDescription }}
              </p>
              <img :src="logo ? logo?.source : 'assets/images/no-image.png'" alt="logo" class="max-w-[128px] max-h-[128px] w-full" />
            </div>
          </div>
          <!-- <div>
            <h3
              class="text-sm font-semibold text-gray-400 uppercase tracking-wider"
            >
              Coleções
            </h3>
            <div class="mt-4 space-y-4">
              <RouterLink
                v-for="collection in collections"
                :to="{ name: 'shop', query: { collectionId: collection.id } }"
                class="text-base text-gray-500 hover:text-gray-900 block"
                >{{ collection.title }}</RouterLink
              >
            </div>
          </div> -->
        </div>
        <div class="col-span-1" v-if="ecommerce.visual?.displayFooterCategories">
          <h3
            class="text-sm font-semibold text-gray-400 uppercase tracking-wider"
          >
            Categorias
          </h3>
          <div class="mt-4 space-y-4">
            <RouterLink
              v-for="category in categories"
              :to="{ name: 'shop', query: { categoryId: category.id } }"
              class="text-base text-gray-500 hover:text-gray-900 block"
              >{{ category.title }}</RouterLink
            >
          </div>
        </div>
        <div class="col-span-1" v-if="ecommerce.visual?.displayFooterCollections">
          <h3
            class="text-sm font-semibold text-gray-400 uppercase tracking-wider"
            :style="{ color: ecommerce.visual?.colorFooterLinkTitle }"
          >
            Coleções
          </h3>
          <div class="mt-4 space-y-4">
            <RouterLink
              v-for="collection in collections"
              :to="{ name: 'shop', query: { categoryId: collection.id } }"
              class="text-base text-gray-500 hover:text-gray-900 block"
              >{{ collection.title }}</RouterLink
            >
          </div>
        </div>
        <div class="col-span-1" v-if="ecommerce.visual?.displayFooterContact">
          <h3
            class="text-sm font-semibold text-gray-400 uppercase tracking-wider"
            :style="{ color: ecommerce.visual?.colorFooterLinkTitle }"
          >
            Contato
          </h3>
          <div class="mt-4 space-y-4">
            <a
              href="#"
              class="text-base text-gray-500 hover:text-gray-900 block overflow-hidden"
              v-if="ecommerce.email"
              >{{ ecommerce.email }}</a
            >
            <a
              href="#"
              class="text-base text-gray-500 hover:text-gray-900 block"
              v-if="ecommerce.phoneNumber"
              >{{ ecommerce.phoneNumber }}</a
            >
            <a
              href="#"
              class="text-base text-gray-500 hover:text-gray-900 block"
              v-if="ecommerce.whatsapp"
              >{{ ecommerce.whatsapp }}</a
            >
          </div>
        </div>
        <div class="col-span-1" v-if="ecommerce.visual?.displayFooterSocialMedia">
          <h3
            class="text-sm font-semibold text-gray-400 uppercase tracking-wider"
            :style="{ color: ecommerce.visual?.colorFooterLinkTitle }"
          >
            Redes sociais
          </h3>
          <div class="mt-4 space-y-4">
            <a
              href="#"
              class="text-base text-gray-500 hover:text-gray-900 block"
              v-if="ecommerce.twitter"
              >{{ ecommerce.twitter }}</a
            >
            <a
              href="#"
              class="text-base text-gray-500 hover:text-gray-900 block"
              v-if="ecommerce.instagram"
              >{{ ecommerce.instagram }}</a
            >
            <a
              href="#"
              class="text-base text-gray-500 hover:text-gray-900 block"
              v-if="ecommerce.facebook"
              >{{ ecommerce.facebook }}</a
            >
            <a
              href="#"
              class="text-base text-gray-500 hover:text-gray-900 block"
              v-if="ecommerce.youtube"
              >{{ ecommerce.youtube }}</a
            >
            <a
              href="#"
              class="text-base text-gray-500 hover:text-gray-900 block"
              v-if="ecommerce.linkedin"
              >{{ ecommerce.linkedin }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </footer>
  <!-- ./footer -->

  <!-- copyright -->
  <div class="bg-gray-800 py-4" :style="{ backgroundColor: ecommerce.visual?.colorFooterBottom }">
    <div class="container flex items-center justify-between">
      <p :style="{ color: ecommerce.visual?.colorFooterLink }">Copyright &copy; {{ ecommerce.title }} - {{ formatDocument(ecommerce.document) }} - {{ new Date().getFullYear() }}. Todos os direitos reservados.</p>
      <div class="flex gap-2 items-center flex-wrap">
        <img src="/assets/images/payment-methods/pix.png" alt="methods" class="h-5" />
        <img src="/assets/images/payment-methods/boleto.jpeg" alt="methods" class="h-5" />
        <img src="/assets/images/payment-methods/bandeiras/elo.png" alt="methods" class="h-5" />
        <img src="/assets/images/payment-methods/bandeiras/hipercard.svg" alt="methods" class="h-5" />
        <img src="/assets/images/payment-methods/bandeiras/master.svg" alt="methods" class="h-5" />
        <img src="/assets/images/payment-methods/bandeiras/visa.svg" alt="methods" class="h-5" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useEcommerce } from '@/composables/useEcommerce';
import { useCategory } from '@/composables/useCategory';
import { useCollection } from '@/composables/useCollection';
import { useFormat } from '@/composables/useFormat';

const { formatDocument } = useFormat()
const { ecommerce, logo } = useEcommerce()
const { categories } = useCategory()
const { collections } = useCollection()
</script>

<style lang="scss">
$footer-link-color: v-bind('ecommerce.visual?.colorFooterLink');
$footer-link-hover-color: v-bind('ecommerce.visual?.colorFooterLinkHover');
$footer-link-font-family: v-bind('ecommerce.visual?.footerLinkFontFamily');
$footer-link-font-size: v-bind('ecommerce.visual?.footerLinkFontSize');
$footer-link-font-weight: v-bind('ecommerce.visual?.footerLinkFontWeight');

footer a {
  color: $footer-link-color !important;
  font-family: $footer-link-font-family;
  font-size: $footer-link-font-size;
  font-weight: $footer-link-font-weight;
  &:hover {
    color: $footer-link-hover-color !important
  }
}

footer {
  h3 {
    color: v-bind('ecommerce.visual?.colorFooterLinkTitle') !important;
    font-family: v-bind('ecommerce.visual?.footerLinkTitleFontFamily') !important;
    font-size: v-bind('ecommerce.visual?.footerLinkTitleFontSize') !important;
    font-weight: v-bind('ecommerce.visual?.footerLinkTitleFontWeight') !important;
  }
}
</style>