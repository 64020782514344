import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "grid sm:grid-cols-2 gap-4 show-review-modal__container max-h-full max-w-full"
}
const _hoisted_2 = { class: "relative col-span-1 max-w-full" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "p-4 col-span-1 max-h-full overflow-auto sm:pt-[24px]" }
const _hoisted_5 = { class: "flex items-center gap-2" }
const _hoisted_6 = { class: "font-semibold" }
const _hoisted_7 = { class: "flex gap-1 text-sm text-yellow-400" }
const _hoisted_8 = { class: "text-xs text-gray-400 mb-4" }
const _hoisted_9 = { class: "text-sm" }

import Modal from '@/components/modal/Modal.vue';
import { Review, ReviewMedia } from '@/interfaces/ecommerce/Review';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import RatingInput from '@/components/inputs/RatingInput.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ShowReviewModal',
  props: {
    open: { type: Boolean },
    review: {}
  },
  emits: ['update:open'],
  setup(__props: any) {




const SwiperNavigation = Navigation;
const SwiperPagination = Pagination;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Modal, {
    width: "800px",
    height: "400px",
    open: _ctx.open,
    "onUpdate:open": _cache[1] || (_cache[1] = v => _ctx.$emit('update:open', v)),
    "z-index": 80
  }, {
    default: _withCtx(() => [
      (_ctx.review)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("button", {
              class: "absolute top-[24px] right-[16px] z-50",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:open', false)))
            }, _cache[2] || (_cache[2] = [
              _createElementVNode("svg", {
                xmlns: "http://www.w3.org/2000/svg",
                width: "16",
                height: "16",
                viewBox: "0 0 16 16",
                fill: "none"
              }, [
                _createElementVNode("g", { "clip-path": "url(#clip0_438_337)" }, [
                  _createElementVNode("path", {
                    d: "M9.29404 7.89904L15.5 1.68604C15.6857 1.50039 15.79 1.24859 15.79 0.986043C15.79 0.723492 15.6857 0.471695 15.5 0.286043C15.3144 0.100392 15.0626 -0.00390625 14.8 -0.00390625C14.5375 -0.00390625 14.2857 0.100392 14.1 0.286043L7.90004 6.49904L1.68604 0.289043C1.50039 0.103392 1.24859 -0.00090625 0.986043 -0.00090625C0.723492 -0.00090625 0.471695 0.103392 0.286043 0.289043C0.100392 0.474695 -0.00390625 0.726492 -0.00390625 0.989043C-0.00390625 1.25159 0.100392 1.50339 0.286043 1.68904L6.50004 7.89904L0.289043 14.107C0.103392 14.2927 -0.00090625 14.5445 -0.00090625 14.807C-0.00090625 15.0696 0.103392 15.3214 0.289043 15.507C0.474695 15.6927 0.726492 15.797 0.989043 15.797C1.25159 15.797 1.50339 15.6927 1.68904 15.507L7.90004 9.29304L14.108 15.499C14.2937 15.6847 14.5455 15.789 14.808 15.789C15.0706 15.789 15.3224 15.6847 15.508 15.499C15.6937 15.3134 15.798 15.0616 15.798 14.799C15.798 14.5365 15.6937 14.2847 15.508 14.099L9.29404 7.89904Z",
                    fill: "#090909"
                  })
                ]),
                _createElementVNode("defs", null, [
                  _createElementVNode("clipPath", { id: "clip0_438_337" }, [
                    _createElementVNode("rect", {
                      width: "15.794",
                      height: "15.793",
                      fill: "white"
                    })
                  ])
                ])
              ], -1)
            ])),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_unref(Swiper), {
                modules: [_unref(SwiperNavigation), _unref(SwiperPagination)],
                "slides-per-view": 1,
                "space-between": 0,
                navigation: true,
                pagination: { clickable: true },
                class: "h-[400px] w-full max-w-[100dvw]"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.review.media.filter(m => !((m as ReviewMedia).isThumb)), (media, index) => {
                    return (_openBlock(), _createBlock(_unref(SwiperSlide), {
                      class: "w-full max-w-full",
                      key: index
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("img", {
                          src: (media as ReviewMedia).source,
                          class: "w-full h-full object-cover sm:rounded-tl-[24px] sm:rounded-bl-[24px]",
                          alt: ""
                        }, null, 8, _hoisted_3)
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modules"])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.review.name), 1)
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(RatingInput, {
                  value: _ctx.review.rating!,
                  name: 'showReviewRating',
                  id: 'showReviewRating',
                  disabled: true
                }, null, 8, ["value"])
              ]),
              _createElementVNode("p", _hoisted_8, " Avaliado em " + _toDisplayString(new Date(_ctx.review.createdAt).toLocaleDateString('pt-BR')), 1),
              _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.review.comment), 1)
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["open"]))
}
}

})