<template>
  <PinkThemeLayout>
    <!-- banner -->
    <div
      class="relative"
      v-if="topBanners?.length === 1"
    >
      <img class="w-full" :src="topBanners[0].url" alt="" srcset="">
      <div class="container">
        <div class="absolute bottom-[20%] mt-12">
          <a v-if="topBanners[0].buttonText"
            :href="topBanners[0].redirectUrl"
            class="bg-primary border border-primary text-white px-8 py-3 font-medium rounded-md hover:bg-transparent hover:text-primary"
            >{{ topBanners[0].buttonText }}</a
          >
        </div>
      </div>
    </div>
    <div class="relative" v-else-if="topBanners?.length ?? 0 > 1">
      <swiper
        :modules="[SwiperAutoplay, SwiperPagination, SwiperNavigation]"
        :autoplay="{ delay: 5000, disableOnInteraction: false }"
        :pagination="{ clickable: true }"
        :navigation="true"
        :loop="true"
      >
        <swiper-slide v-for="banner in topBanners" :key="banner.id">
          <img class="w-full" :src="banner.url" alt="" srcset="">
          <div class="container">
            <div class="absolute bottom-[20%] mt-12">
              <a v-if="banner.buttonText"
                :href="banner.redirectUrl"
                class="bg-primary border border-primary text-white px-8 py-3 font-medium rounded-md hover:bg-transparent hover:text-primary"
                >{{ banner.buttonText }}</a
              >
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <!-- ./banner -->
  
    <!-- features -->
    <!-- <div class="container py-16">
      <div
        class="w-10/12 grid grid-cols-1 md:grid-cols-3 gap-6 mx-auto justify-center"
      >
        <div
          class="border border-primary rounded-sm px-3 py-6 flex justify-center items-center gap-5"
        >
          <img
            src="assets/images/icons/delivery-van.svg"
            alt="Delivery"
            class="w-12 h-12 object-contain"
          />
          <div>
            <h4 class="font-medium capitalize text-lg">Frete Grátis</h4>
            <p class="text-gray-500 text-sm">Pedido acima de R$ 200</p>
          </div>
        </div>
        <div
          class="border border-primary rounded-sm px-3 py-6 flex justify-center items-center gap-5"
        >
          <img
            src="assets/images/icons/money-back.svg"
            alt="Delivery"
            class="w-12 h-12 object-contain"
          />
          <div>
            <h4 class="font-medium capitalize text-lg">Reembolso</h4>
            <p class="text-gray-500 text-sm">30 dias</p>
          </div>
        </div>
        <div
          class="border border-primary rounded-sm px-3 py-6 flex justify-center items-center gap-5"
        >
          <img
            src="assets/images/icons/service-hours.svg"
            alt="Delivery"
            class="w-12 h-12 object-contain"
          />
          <div>
            <h4 class="font-medium capitalize text-lg">24h/7d Suporte</h4>
            <p class="text-gray-500 text-sm">Suporte ao cliente</p>
          </div>
        </div>
      </div>
    </div> -->
    <!-- ./features -->
  
    <!-- categories -->
    <!-- <div class="container py-16">
      <h2 class="text-2xl font-medium text-gray-800 uppercase mb-6">
        categorias
      </h2>
      <div class="grid grid-cols-2 sm:grid-cols-3 gap-3">
        <RouterLink :to="{ name: 'shop', query: { categoryId: category.id } }" class="relative rounded-sm overflow-hidden group" v-for="(category, index) in categories" :key="index">
          <img
            src="assets/images/category/category-1.jpg"
            alt="category 1"
            class="w-full"
            @error="($event.target as HTMLImageElement).src = '/assets/images/no-image.png'"
          />
          <a
            href="#"
            class="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center text-xl text-white font-roboto font-medium group-hover:bg-opacity-60 transition"
            >{{ category.title }}</a
          >
        </RouterLink>
      </div>
    </div> -->
    <!-- ./categories -->

    <!-- categories -->
    <div class="py-16 mx-auto" :style="{ maxWidth: ecommerce.visual?.collectionSectionWidth }">
      <h2 v-if="ecommerce.visual?.displayCollectionSectionTitle" class="text-2xl font-medium text-gray-800 uppercase mb-6" 
      :style="{ fontSize: ecommerce.visual?.collectionSectionTitleFontSize, fontFamily: ecommerce.visual?.collectionSectionTitleFontFamily, fontWeight: ecommerce.visual?.collectionSectionTitleFontWeight, color: ecommerce.visual?.colorCollectionSectionTitle }">
        coleções
      </h2>
      <div class="grid grid-cols-2 gap-3 collection-section" :class="[`grid-cols-${ecommerce.visual?.collectionsPerRow ?? 3}`]">
        <RouterLink :to="{ name: 'collections', params: { slug: collection.slug } }" class="relative rounded-sm overflow-hidden group col-span-1" v-for="collection in collections" :style="{ maxWidth: ecommerce.visual?.collectionWidth }">
          <div class="relative h-auto" :style="{ maxWidth: ecommerce.visual?.collectionWidth }">
            <img
              :src="collection.media.length ? collection.media[0].source : 'assets/images/no-image.png'"
              alt="category 1"
              class="w-full h-full object-fit"
              @error="($event.target as HTMLImageElement).src = '/assets/images/no-image.png'"
              :style="{ borderRadius: ecommerce.visual?.collectionBorderRadius, maxWidth: ecommerce.visual?.collectionWidth, height: '100%' }"
            />
            <a
              href="#"
              class="absolute inset-0 flex items-center justify-center text-xl text-white font-roboto font-medium group-hover:bg-opacity-60 transition p-4"
              :class="[ecommerce.visual?.displayCollectionOverlay ? 'bg-black bg-opacity-40' : '']"
              :style="{ fontFamily: ecommerce.visual?.collectionTitleFontFamily, color: ecommerce.visual?.colorCollectionTitle, fontSize: ecommerce.visual?.collectionTitleFontSize, fontWeight: ecommerce.visual?.collectionTitleFontWeight }"
              v-if="ecommerce.visual?.collectionTitlePlacement === 'image-center'"
              >{{ collection.title }}</a
            >
          </div>
          <h5 v-if="ecommerce.visual?.collectionTitlePlacement === 'bellow'" 
            class="text-center pt-4"
            :style="{ fontFamily: ecommerce.visual?.collectionTitleFontFamily, color: ecommerce.visual?.colorCollectionTitle, fontSize: ecommerce.visual?.collectionTitleFontSize, fontWeight: ecommerce.visual?.collectionTitleFontWeight }">{{ collection.title }}</h5>
        </RouterLink>
      </div>
    </div>
    <!-- ./categories -->
  
    <!-- new arrival -->
    <template v-for="category in categories" :key="category.id">
      <div class="pb-16 pt-16 mx-auto category-section" v-if="category.products?.length" :style="{ maxWidth: ecommerce.visual?.productSectionWidth }">
        <h2 class="text-2xl font-medium text-gray-800 mb-6"
        v-if="ecommerce.visual?.displayProductSectionTitle"
        :style="{ fontSize: ecommerce.visual?.productSectionTitleFontSize, fontFamily: ecommerce.visual?.productSectionTitleFontFamily, fontWeight: ecommerce.visual?.productSectionTitleFontWeight, color: ecommerce.visual?.colorProductSectionTitle }">
          {{ category.title }}
        </h2>
        <ProductSection :products="category.products" />
      </div>
    </template>
    <!-- ./new arrival -->
  
    <!-- ads -->
    <div class="mx-auto flex flex-col gap-8 bottom-banner-section py-16" :style="{ maxWidth: ecommerce.visual?.bottomBannerWidth }">
      <a class="relative" :href="banner.redirectUrl" v-for="banner in bottomBanners">
        <img :src="banner.url" alt="ads" class="w-full" />
        <a v-if="banner.buttonText"
            :href="banner.redirectUrl"
            class="bg-primary border border-primary text-white px-8 py-3 font-medium rounded-md hover:bg-transparent hover:text-primary absolute bottom-[25%] left-[10%]"
            >{{ banner.buttonText }}</a
          >
      </a>
    </div>
    <!-- ./ads -->
  </PinkThemeLayout>
</template>

<script lang="ts" setup>
import PinkThemeLayout from './layouts/PinkThemeLayout.vue';
import { useCollection } from '@/composables/useCollection';
import { useCategory } from '@/composables/useCategory';
import { useEcommerce } from '@/composables/useEcommerce';

const { ecommerce, topBanners, bottomBanners } = useEcommerce()
const { categories } = useCategory()
const { collections } = useCollection()

import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import ProductSection from '@/components/sections/ProductSection.vue';
const SwiperAutoplay = Autoplay;
const SwiperPagination = Pagination;
const SwiperNavigation = Navigation;
</script>

<style lang="scss" scoped>
$primary-color: v-bind('ecommerce.visual?.colorPrimary');

:deep(.swiper-button-next),
:deep(.swiper-button-prev) {
  color: #000;
  background: rgba(255, 255, 255, 0.8);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
}

:deep(.swiper-button-next::after),
:deep(.swiper-button-prev::after) {
  font-size: 20px;
  font-weight: bold;
}

:deep(.swiper-pagination-bullet) {
  background: $primary-color;
  opacity: 0.5;
}

:deep(.swiper-pagination-bullet-active) {
  opacity: 1;
}

:deep(.swiper-button-next) {
  right: 10px;
}

:deep(.swiper-button-prev) {
  left: 10px;
}

@media screen and (max-width: 700px) {
  .category-section {
    padding: 1rem;
  }
}

@media screen and (max-width: 1000px) {
  .collection-section {
    grid-template-columns: repeat(v-bind("(ecommerce.visual?.collectionsPerRow ?? 3) - 1"), 1fr);
  }
}

@media screen and (max-width: 900px) {
  .collection-section {
    grid-template-columns: repeat(v-bind("(ecommerce.visual?.collectionsPerRow ?? 3) - 2"), 1fr);
  }
}


@media screen and (max-width: 700px) {
  .collection-section {
    padding: 1rem;
    grid-template-columns: v-bind("ecommerce.visual?.mobileCollectionsPerRow === 2 ? '1fr 1fr' : '1fr'");
    justify-content: space-between;
    row-gap: 40px;
  }
}
</style>