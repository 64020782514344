<template>
  <div class="bg-green-500 text-white overflow-hidden" :style="{ backgroundColor: ecommerce.visual?.colorRotatingBannerBackground, color: ecommerce.visual?.colorRotatingBannerText }">
    <div class="max-w-screen-2xl mx-auto relative">
      <div class="py-2 px-4">
        <Transition
          mode="out-in"
          enter-active-class="transition duration-500 ease-out"
          enter-from-class="transform translate-y-4 opacity-0"
          enter-to-class="transform translate-y-0 opacity-100"
          leave-active-class="transition duration-500 ease-in"
          leave-from-class="transform translate-y-0 opacity-100"
          leave-to-class="transform -translate-y-4 opacity-0"
        >
          <div
            :key="currentIndex"
            class="flex items-center justify-center text-center font-medium"
          >
            {{ messages[currentIndex] }}
          </div>
        </Transition>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useEcommerce } from '@/composables/useEcommerce';
import { ref, onMounted, onUnmounted } from 'vue'

const props = defineProps<{ messages: string[] }>()
const { ecommerce } = useEcommerce()

const currentIndex = ref<number>(0)
let intervalId: number | null = null

const rotateMessages = () => {
  intervalId = setInterval(() => {
    currentIndex.value = (currentIndex.value + 1) % props.messages.length
  }, 3000)
}

onMounted(() => {
  rotateMessages()
})

onUnmounted(() => {
  if (intervalId) {
    clearInterval(intervalId)
  }
})
</script>