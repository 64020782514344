import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "grid grid-cols-5 gap-4 mt-4" }
const _hoisted_3 = ["src", "onClick"]
const _hoisted_4 = ["src", "onClick"]
const _hoisted_5 = {
  key: 0,
  class: "flex items-center mb-4"
}
const _hoisted_6 = { class: "text-xs text-gray-500 ml-3" }
const _hoisted_7 = { class: "space-y-2" }
const _hoisted_8 = {
  key: 0,
  class: "text-gray-800 font-semibold space-x-2"
}
const _hoisted_9 = {
  key: 0,
  class: "text-green-600"
}
const _hoisted_10 = {
  key: 1,
  class: "text-red-600"
}
const _hoisted_11 = {
  key: 1,
  class: "space-x-2"
}
const _hoisted_12 = { class: "text-gray-600" }
const _hoisted_13 = { class: "flex items-baseline mb-1 space-x-2" }
const _hoisted_14 = {
  key: 0,
  class: "flex items-baseline mb-1 space-x-2"
}
const _hoisted_15 = { class: "text-xl text-primary font-semibold" }
const _hoisted_16 = { class: "text-sm text-gray-400 line-through" }
const _hoisted_17 = {
  key: 1,
  class: "text-xl text-primary font-semibold"
}
const _hoisted_18 = { class: "text-xl text-primary font-semibold" }
const _hoisted_19 = { class: "text-sm text-gray-400 line-through" }
const _hoisted_20 = {
  key: 3,
  class: "text-xl text-primary font-semibold"
}
const _hoisted_21 = ["innerHTML"]
const _hoisted_22 = { class: "text-sm text-gray-800 uppercase mb-1" }
const _hoisted_23 = { class: "flex items-center gap-2" }
const _hoisted_24 = ["name", "id", "value", "disabled", "checked"]
const _hoisted_25 = ["for"]
const _hoisted_26 = { class: "mt-4" }
const _hoisted_27 = { class: "flex border border-gray-300 text-gray-600 divide-x divide-gray-300 w-max" }
const _hoisted_28 = { class: "h-8 w-8 text-base flex items-center justify-center" }
const _hoisted_29 = { class: "mt-6 flex gap-3 pb-5 pt-5" }
const _hoisted_30 = ["disabled"]
const _hoisted_31 = { class: "sm:w-3/5 pt-6" }
const _hoisted_32 = ["innerHTML"]
const _hoisted_33 = { class: "table-auto border-collapse w-full text-left text-gray-600 text-sm mt-6" }
const _hoisted_34 = { key: 0 }
const _hoisted_35 = { class: "py-2 px-4 border border-gray-300" }
const _hoisted_36 = { key: 1 }
const _hoisted_37 = { class: "py-2 px-4 border border-gray-300" }
const _hoisted_38 = { key: 2 }
const _hoisted_39 = { class: "py-2 px-4 border border-gray-300" }
const _hoisted_40 = { key: 3 }
const _hoisted_41 = { class: "py-2 px-4 border border-gray-300" }
const _hoisted_42 = { key: 4 }
const _hoisted_43 = { class: "py-2 px-4 border border-gray-300" }
const _hoisted_44 = { class: "flex flex-col sm:flex-row gap-4 sm:items-center justify-between mb-4" }
const _hoisted_45 = { key: 0 }
const _hoisted_46 = { class: "flex items-center gap-2" }
const _hoisted_47 = { class: "font-bold text-[28px]" }
const _hoisted_48 = { class: "text-sm" }
const _hoisted_49 = { class: "overflow-hidden" }
const _hoisted_50 = { class: "flex gap-4 items-center col-span-2 grid grid-cols-10" }
const _hoisted_51 = ["for"]
const _hoisted_52 = ["id", "onChange"]
const _hoisted_53 = ["disabled"]
const _hoisted_54 = {
  key: 1,
  role: "status"
}
const _hoisted_55 = { class: "columns-2 md:columns-2 lg:columns-3 xl:columns-4 2xl:columns-5 gap-4 space-y-4" }
const _hoisted_56 = ["onClick"]
const _hoisted_57 = { class: "flex flex-col" }
const _hoisted_58 = { class: "w-full" }
const _hoisted_59 = ["src"]
const _hoisted_60 = { class: "p-4" }
const _hoisted_61 = { class: "flex items-center gap-2" }
const _hoisted_62 = { class: "font-semibold" }
const _hoisted_63 = { class: "flex gap-1 text-sm text-yellow-400" }
const _hoisted_64 = { class: "text-xs text-gray-400 mb-4" }
const _hoisted_65 = { class: "text-sm" }

import { useProduct } from '@/composables/useProduct';
import PinkThemeLayout from '../layouts/PinkThemeLayout.vue';
import { generateProductSlug } from '@/interfaces/products/Product';
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { useCategory } from '@/composables/useCategory';
import axiosClient from '@/config/axios';
import { Review, ReviewMedia } from '@/interfaces/ecommerce/Review';
import RatingInput from '@/components/inputs/RatingInput.vue';
import SpinnerLoader from '@/components/loaders/SpinnerLoader.vue';
import { Attribute, Variant } from '@/interfaces/products/Variant';
import { useCart } from '@/composables/useCart';
import ShowReviewModal from './components/ShowReviewModal.vue';
import { toast } from 'vue3-toastify'
import { useEcommerce } from '@/composables/useEcommerce';
import ProductSection from '@/components/sections/ProductSection.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'PinkThemeShowProductView',
  props: {
    slug: {}
  },
  setup(__props: any) {

const { ecommerce } = useEcommerce()
const isShowReviewModalOpen = ref(false)
const selectedReview = ref<Review>()
const props = __props
const { products } = useProduct()
const { categories } = useCategory()
const product = computed(() => {
  const product = products.value.find((p) => generateProductSlug(p.title) === props.slug)
  return product
})
const { addIntoCart, isAddingProductIntoCart } = useCart()
const quantity = ref(1)
const category = computed(() => categories.value.find((c) => c.productIds.includes(product.value?.id ?? 0)))
const relatedProducts = computed(() => products.value.filter((p) => category.value?.productIds.includes(p.id) && p.id !== product.value?.id))
const selectedImage = ref<string>()
const reviewsListComponent = ref<HTMLElement>()
const reviewPayload = ref<Pick<Partial<Review>, 'comment' | 'name' | 'email' | 'rating' | 'media'>>({ media: [] })
const reviews = ref<Review[]>()
const isReviewsLoading = ref(false)
const isCreatingReview = ref(false)
const images = ref<File[]>([])
const isReviewFormOpen = ref(false)
const averageRating = computed(() => {
  if (!reviews.value?.length) return 0;

  const totalRating = reviews.value.reduce((sum, review) => sum + (review.rating || 0), 0);
  const averageRating = totalRating / reviews.value.length;

  return parseFloat(averageRating.toFixed(1)).toFixed(1);
});

const roundedAverageRating = computed(() => {
  if (!reviews.value?.length) return 0

  const totalRating = reviews.value.reduce((sum, review) => sum + (review.rating || 0), 0);
  const averageRating = totalRating / reviews.value.length;

  return Math.round(averageRating * 2) / 2;
})

function selectVariantByForm() {
  const selectedValues: { [key: string]: string } = {};
  
  for (const name of attributeNames.value) {
    const selectedInput = document.querySelector(`input[name="${name}"]:checked`) as HTMLInputElement;
    if (selectedInput) {
      selectedValues[name] = selectedInput.value;
    }
  }

  const variant = product.value?.variants.find((variant) => 
    variant.attributes?.every((attr) => selectedValues[attr.name] === attr.value)
  )
  
  selectedVariant.value = variant as Variant;
}
const imageLoaders = ref([
  { isLoading: false, progress: 0 },
  { isLoading: false, progress: 0 },
  { isLoading: false, progress: 0 },
  { isLoading: false, progress: 0 },
  { isLoading: false, progress: 0 }
])
const availableVariants = computed(() => {
  if (!product.value || !product.value.variants) return [];

  return product.value.variants.map((v) => {
    const updatedAttributes = v.attributes?.map((attr) => {
      const isAvailable = !selectedVariant.value || 
        selectedVariant.value.attributes[0].name === (v.attributes ?? [])[0].name && selectedVariant.value.attributes[0].value === (v.attributes ?? [])[0].value
      const checked = !!selectedVariant.value?.attributes.find((selectedAttr) => selectedAttr.name === attr.name && selectedAttr.value === attr.value)

      return {
        ...attr,
        isAvailable,
        checked
      };
    });

    return {
      ...v,
      attributes: updatedAttributes
    };
  });
});

const attributeNames = computed(() => {
  let names: string[] = []

  availableVariants.value?.forEach((v) => {
    v.attributes?.forEach((attr) => {
      if (!names?.includes(attr.name)) {
        names?.push(attr.name)
      }
    })
  })
  
  return names
})
const selectedVariant = ref<Variant>()

function getAttributesByName(name: string): Attribute[] {
  const attributes: Attribute[] = []

  availableVariants.value?.forEach((v) => {
    v.attributes?.forEach((attr) => {
      if (attr.name === name && !attributes.find((a) => a.value === attr.value)) {
        attributes.push(attr)
      }
    })
  })

  return attributes
}

function selectVariant(e: Event) {
  const attributeName = (e.target as HTMLInputElement).name
  const attributeValue = (e.target as HTMLInputElement).value

  availableVariants.value?.forEach((v) => {
    v.attributes?.forEach((attr) => {
      if (attr.name === attributeName && attr.value === attributeValue) {
        selectedVariant.value = v as Variant

        selectedImage.value = v.images![0]?.source ?? product.value?.images[0].source
      }
    })
  })
}

function uploadReviewImage(e: Event, index: number) {
  const reader = new FileReader()

  reader.onload = () => {
    imageLoaders.value[index].isLoading = false
    imageLoaders.value[index].progress = 100
    if (typeof reader.result === 'string' && reviewPayload.value.media) {
      reviewPayload.value.media[index] = reader.result
    }
  }

  reader.onprogress = (event: ProgressEvent<FileReader>) => {
    if (event.lengthComputable) {
      const percentComplete = Math.round((event.loaded / event.total) * 100)
      imageLoaders.value[index].progress = percentComplete
    }
  }

  const fileInput = (e.target as HTMLInputElement)

  if (fileInput && fileInput.files) {
    imageLoaders.value[index].isLoading = true
    imageLoaders.value[index].progress = 0
    reader.readAsDataURL(fileInput.files[0])
    images.value[index] = fileInput.files[0]
  }
}

function getReviewsByProductId(productId: number) {
  isReviewsLoading.value = true 

  axiosClient.get(`/reviews/${productId}`)
    .then((res) => {
      reviews.value = res.data
    })
    .finally(() => isReviewsLoading.value = false)
}

function createReview() {
  if (!isCreatingReview.value) {
    isCreatingReview.value = true;
  
    const formData = new FormData();
    
    formData.append('name', reviewPayload.value.name!);
    formData.append('email', reviewPayload.value.email!);
    formData.append('rating', String(reviewPayload.value.rating!));
    formData.append('comment', reviewPayload.value.comment!);
    formData.append('productId', String(product.value?.id));
  
    images.value.forEach((file, index) => {
      formData.append(`images`, file as unknown as File);
    });
  
    axiosClient.post('/reviews', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(() => {
        reviewPayload.value = { media: [] }
        images.value = []
        toast.success('Avaliação enviada com sucesso')
      })
      .finally(() => {
        isCreatingReview.value = false;
      });
  }
}

onMounted(() => {
  window.addEventListener("scroll", handleScroll)
})

onUnmounted(() => {
  window.removeEventListener("scroll", handleScroll)
})

watch(() => product.value, () => reviews.value = undefined)

 const handleScroll = (e: any) => {
  if (reviewsListComponent.value && reviewsListComponent.value?.getBoundingClientRect().bottom < window.innerHeight && !isReviewsLoading.value && !reviews.value) {
    getReviewsByProductId(product.value!.id)
  }
 }


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(PinkThemeLayout, null, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: "grid sm:grid-cols-2 gap-6 pt-4 mx-auto px-4 sm:px-0",
        style: _normalizeStyle({ maxWidth: _unref(ecommerce).visual?.productPageWidth, fontFamily: _unref(ecommerce).visual?.productDefaultFontFamily })
      }, [
        _createElementVNode("div", null, [
          _createElementVNode("img", {
            src: selectedImage.value ? selectedImage.value : (product.value?.images?.length ? product.value.images[0].source : '/assets/images/no-image.png'),
            alt: "product",
            class: "w-full object-contain",
            onError: _cache[0] || (_cache[0] = ($event: any) => (($event.target as HTMLImageElement).src = '/assets/images/no-image.png')),
            style: {"height":"30rem"}
          }, null, 40, _hoisted_1),
          _createElementVNode("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(product.value?.images.filter(i => !i.isThumb), (img) => {
              return (_openBlock(), _createElementBlock("img", {
                src: img.source,
                alt: "product2",
                class: _normalizeClass(["w-full cursor-pointer border rounded-md", img.source === selectedImage.value ? 'border-primary' : '']),
                onClick: ($event: any) => (selectedImage.value = img.source)
              }, null, 10, _hoisted_3))
            }), 256)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(product.value?.variants.flatMap(v => v.images), (img) => {
              return (_openBlock(), _createElementBlock(_Fragment, null, [
                (img && (!selectedVariant.value || (selectedVariant.value.variantId === img.variantId)))
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      src: img.source,
                      alt: "product2",
                      class: _normalizeClass(["w-full cursor-pointer border rounded-md", { 
                'opacity-04': selectedVariant.value && selectedVariant.value.id === img.variantId,
                'border-primary': img.source === selectedImage.value
              }]),
                      onClick: ($event: any) => (selectedImage.value = img.source)
                    }, null, 10, _hoisted_4))
                  : _createCommentVNode("", true)
              ], 64))
            }), 256))
          ])
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("h2", {
            class: "text-3xl font-medium mb-2 leading-[36px]",
            style: _normalizeStyle({ color: _unref(ecommerce).visual?.colorProductTitle, fontFamily: _unref(ecommerce).visual?.productTitleFontFamily, fontSize: _unref(ecommerce).visual?.productTitleFontSize, fontWeight: _unref(ecommerce).visual?.productTitleFontWeight })
          }, _toDisplayString(product.value?.title), 5),
          (product.value?.reviews?.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _cache[10] || (_cache[10] = _createElementVNode("div", { class: "flex gap-1 text-sm text-yellow-400" }, [
                  _createElementVNode("span", null, [
                    _createElementVNode("svg", {
                      xmlns: "http://www.w3.org/2000/svg",
                      width: "21",
                      height: "20",
                      viewBox: "0 0 25 24",
                      fill: "none"
                    }, [
                      _createElementVNode("path", {
                        d: "M13.7457 3.51001L15.5057 7.03001C15.7457 7.52001 16.3857 7.99001 16.9257 8.08001L20.1157 8.61001C22.1557 8.95001 22.6357 10.43 21.1657 11.89L18.6857 14.37C18.2657 14.79 18.0357 15.6 18.1657 16.18L18.8757 19.25C19.4357 21.68 18.1457 22.62 15.9957 21.35L13.0057 19.58C12.4657 19.26 11.5757 19.26 11.0257 19.58L8.03566 21.35C5.89566 22.62 4.59566 21.67 5.15566 19.25L5.86566 16.18C5.99566 15.6 5.76566 14.79 5.34566 14.37L2.86566 11.89C1.40566 10.43 1.87566 8.95001 3.91566 8.61001L7.10566 8.08001C7.63566 7.99001 8.27566 7.52001 8.51566 7.03001L10.2757 3.51001C11.2357 1.60001 12.7957 1.60001 13.7457 3.51001Z",
                        fill: "#171717"
                      })
                    ])
                  ]),
                  _createElementVNode("span", null, [
                    _createElementVNode("svg", {
                      xmlns: "http://www.w3.org/2000/svg",
                      width: "21",
                      height: "20",
                      viewBox: "0 0 25 24",
                      fill: "none"
                    }, [
                      _createElementVNode("path", {
                        d: "M13.7457 3.51001L15.5057 7.03001C15.7457 7.52001 16.3857 7.99001 16.9257 8.08001L20.1157 8.61001C22.1557 8.95001 22.6357 10.43 21.1657 11.89L18.6857 14.37C18.2657 14.79 18.0357 15.6 18.1657 16.18L18.8757 19.25C19.4357 21.68 18.1457 22.62 15.9957 21.35L13.0057 19.58C12.4657 19.26 11.5757 19.26 11.0257 19.58L8.03566 21.35C5.89566 22.62 4.59566 21.67 5.15566 19.25L5.86566 16.18C5.99566 15.6 5.76566 14.79 5.34566 14.37L2.86566 11.89C1.40566 10.43 1.87566 8.95001 3.91566 8.61001L7.10566 8.08001C7.63566 7.99001 8.27566 7.52001 8.51566 7.03001L10.2757 3.51001C11.2357 1.60001 12.7957 1.60001 13.7457 3.51001Z",
                        fill: "#171717"
                      })
                    ])
                  ]),
                  _createElementVNode("span", null, [
                    _createElementVNode("svg", {
                      xmlns: "http://www.w3.org/2000/svg",
                      width: "21",
                      height: "20",
                      viewBox: "0 0 25 24",
                      fill: "none"
                    }, [
                      _createElementVNode("path", {
                        d: "M13.7457 3.51001L15.5057 7.03001C15.7457 7.52001 16.3857 7.99001 16.9257 8.08001L20.1157 8.61001C22.1557 8.95001 22.6357 10.43 21.1657 11.89L18.6857 14.37C18.2657 14.79 18.0357 15.6 18.1657 16.18L18.8757 19.25C19.4357 21.68 18.1457 22.62 15.9957 21.35L13.0057 19.58C12.4657 19.26 11.5757 19.26 11.0257 19.58L8.03566 21.35C5.89566 22.62 4.59566 21.67 5.15566 19.25L5.86566 16.18C5.99566 15.6 5.76566 14.79 5.34566 14.37L2.86566 11.89C1.40566 10.43 1.87566 8.95001 3.91566 8.61001L7.10566 8.08001C7.63566 7.99001 8.27566 7.52001 8.51566 7.03001L10.2757 3.51001C11.2357 1.60001 12.7957 1.60001 13.7457 3.51001Z",
                        fill: "#171717"
                      })
                    ])
                  ]),
                  _createElementVNode("span", null, [
                    _createElementVNode("svg", {
                      xmlns: "http://www.w3.org/2000/svg",
                      width: "21",
                      height: "20",
                      viewBox: "0 0 25 24",
                      fill: "none"
                    }, [
                      _createElementVNode("path", {
                        d: "M13.7457 3.51001L15.5057 7.03001C15.7457 7.52001 16.3857 7.99001 16.9257 8.08001L20.1157 8.61001C22.1557 8.95001 22.6357 10.43 21.1657 11.89L18.6857 14.37C18.2657 14.79 18.0357 15.6 18.1657 16.18L18.8757 19.25C19.4357 21.68 18.1457 22.62 15.9957 21.35L13.0057 19.58C12.4657 19.26 11.5757 19.26 11.0257 19.58L8.03566 21.35C5.89566 22.62 4.59566 21.67 5.15566 19.25L5.86566 16.18C5.99566 15.6 5.76566 14.79 5.34566 14.37L2.86566 11.89C1.40566 10.43 1.87566 8.95001 3.91566 8.61001L7.10566 8.08001C7.63566 7.99001 8.27566 7.52001 8.51566 7.03001L10.2757 3.51001C11.2357 1.60001 12.7957 1.60001 13.7457 3.51001Z",
                        fill: "#171717"
                      })
                    ])
                  ]),
                  _createElementVNode("span", null, [
                    _createElementVNode("svg", {
                      xmlns: "http://www.w3.org/2000/svg",
                      width: "21",
                      height: "20",
                      viewBox: "0 0 25 24",
                      fill: "none"
                    }, [
                      _createElementVNode("path", {
                        d: "M13.7457 3.51001L15.5057 7.03001C15.7457 7.52001 16.3857 7.99001 16.9257 8.08001L20.1157 8.61001C22.1557 8.95001 22.6357 10.43 21.1657 11.89L18.6857 14.37C18.2657 14.79 18.0357 15.6 18.1657 16.18L18.8757 19.25C19.4357 21.68 18.1457 22.62 15.9957 21.35L13.0057 19.58C12.4657 19.26 11.5757 19.26 11.0257 19.58L8.03566 21.35C5.89566 22.62 4.59566 21.67 5.15566 19.25L5.86566 16.18C5.99566 15.6 5.76566 14.79 5.34566 14.37L2.86566 11.89C1.40566 10.43 1.87566 8.95001 3.91566 8.61001L7.10566 8.08001C7.63566 7.99001 8.27566 7.52001 8.51566 7.03001L10.2757 3.51001C11.2357 1.60001 12.7957 1.60001 13.7457 3.51001Z",
                        fill: "#171717"
                      })
                    ])
                  ])
                ], -1)),
                _createElementVNode("div", _hoisted_6, "(" + _toDisplayString(product.value?.reviews.length) + " Avaliações)", 1)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_7, [
            (_unref(ecommerce).visual?.displayTopProductStock)
              ? (_openBlock(), _createElementBlock("p", _hoisted_8, [
                  _cache[11] || (_cache[11] = _createElementVNode("span", null, "Disponibilidade: ", -1)),
                  (product.value?.variants[0].quantityStock)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_9, "Em estoque"))
                    : (_openBlock(), _createElementBlock("span", _hoisted_10, "Sem estoque"))
                ]))
              : _createCommentVNode("", true),
            (_unref(ecommerce).visual?.displayTopProductCategory)
              ? (_openBlock(), _createElementBlock("p", _hoisted_11, [
                  _cache[12] || (_cache[12] = _createElementVNode("span", { class: "text-gray-800 font-semibold" }, "Categoria: ", -1)),
                  _createElementVNode("span", _hoisted_12, _toDisplayString(category.value?.title), 1)
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_13, [
            (product.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                  (!selectedVariant.value && product.value.variants[0].promotionalPrice)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createElementVNode("p", _hoisted_15, _toDisplayString(product.value.variants[0].promotionalPrice.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })), 1),
                        _createElementVNode("p", _hoisted_16, _toDisplayString(product.value.variants[0].price?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })), 1)
                      ], 64))
                    : (!selectedVariant.value)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_17, _toDisplayString(product.value.variants[0].price?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })), 1))
                      : (selectedVariant.value.promotionalPrice)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                            _createElementVNode("p", _hoisted_18, _toDisplayString(selectedVariant.value.promotionalPrice.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })), 1),
                            _createElementVNode("p", _hoisted_19, _toDisplayString(selectedVariant.value.price?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })), 1)
                          ], 64))
                        : (_openBlock(), _createElementBlock("p", _hoisted_20, _toDisplayString(selectedVariant.value.price?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })), 1))
                ]))
              : _createCommentVNode("", true)
          ]),
          (_unref(ecommerce).visual?.displayTopProductDescription)
            ? (_openBlock(), _createElementBlock("p", {
                key: 1,
                style: _normalizeStyle({ fontFamily: _unref(ecommerce).visual?.productDefaultFontFamily }),
                class: "mt-4 text-gray-600",
                innerHTML: product.value?.description
              }, null, 12, _hoisted_21))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(attributeNames.value, (attributeName, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: attributeName,
              class: "pt-4"
            }, [
              _createElementVNode("h3", _hoisted_22, _toDisplayString(attributeName), 1),
              _createElementVNode("div", _hoisted_23, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(getAttributesByName(attributeName), (attribute) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "size-selector",
                    key: attribute.value
                  }, [
                    _createElementVNode("input", {
                      type: "radio",
                      name: attributeName,
                      id: attributeName + attribute.value,
                      class: "hidden variant-input",
                      value: attribute.value,
                      onChange: selectVariant,
                      disabled: !attribute.isAvailable && index !== 0,
                      checked: attribute.checked
                    }, null, 40, _hoisted_24),
                    _createElementVNode("label", {
                      for: attributeName + attribute.value,
                      class: "text-xs border border-gray-200 rounded-sm p-2 min-h-6 min-w-6 flex items-center justify-center cursor-pointer shadow-sm text-gray-600",
                      style: _normalizeStyle({ backgroundColor: attribute.type === 'color' ? attribute.typeValue : 'unset' })
                    }, _toDisplayString(attribute.type === 'color' ? '' : attribute.value), 13, _hoisted_25)
                  ]))
                }), 128))
              ])
            ]))
          }), 128)),
          _createElementVNode("div", _hoisted_26, [
            _cache[13] || (_cache[13] = _createElementVNode("h3", { class: "text-sm text-gray-800 uppercase mb-1" }, "Quantidade", -1)),
            _createElementVNode("div", _hoisted_27, [
              _createElementVNode("button", {
                class: "h-8 w-8 text-xl flex items-center justify-center cursor-pointer select-none",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (quantity.value > 1 ? quantity.value-- : ''))
              }, " - "),
              _createElementVNode("div", _hoisted_28, _toDisplayString(quantity.value), 1),
              _createElementVNode("button", {
                class: "h-8 w-8 text-xl flex items-center justify-center cursor-pointer select-none",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (quantity.value++))
              }, " + ")
            ])
          ]),
          _createElementVNode("div", _hoisted_29, [
            _createElementVNode("a", {
              href: "#",
              class: "bg-primary border border-primary text-white px-8 py-2 font-medium rounded uppercase flex items-center gap-2 hover:bg-transparent hover:text-primary transition",
              style: _normalizeStyle({ 'color': _unref(ecommerce).visual?.colorAddProductButtonText, 'backgroundColor': _unref(ecommerce).visual?.colorAddProductButtonBackground }),
              onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => {selectVariantByForm(); _unref(addIntoCart)(selectedVariant.value?.uuid ?? product.value?.variants[0].uuid!, quantity.value);}, ["prevent"])),
              disabled: _unref(isAddingProductIntoCart) || (!!product.value?.variants[0].quantityStock && product.value?.variants[0].quantityStock < 1)
            }, [
              (!_unref(isAddingProductIntoCart))
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    (!product.value?.variants[0].quantityStock || product.value?.variants[0].quantityStock > 0)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _cache[14] || (_cache[14] = _createElementVNode("i", { class: "fa-solid fa-bag-shopping" }, null, -1)),
                          _cache[15] || (_cache[15] = _createTextVNode(" Adicionar ao carrinho "))
                        ], 64))
                      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _createTextVNode(" Produto indisponível ")
                        ], 64))
                  ], 64))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _cache[16] || (_cache[16] = _createElementVNode("svg", {
                      "aria-hidden": "true",
                      class: "w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-primary",
                      viewBox: "0 0 100 101",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg"
                    }, [
                      _createElementVNode("path", {
                        d: "M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z",
                        fill: "currentColor"
                      }),
                      _createElementVNode("path", {
                        d: "M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z",
                        fill: "currentFill"
                      })
                    ], -1)),
                    _cache[17] || (_cache[17] = _createElementVNode("span", null, "Processando...", -1))
                  ], 64))
            ], 12, _hoisted_30)
          ])
        ])
      ], 4),
      _createElementVNode("div", {
        class: "pb-16 pt-8 mx-auto px-4 sm:px-0",
        style: _normalizeStyle({ maxWidth: _unref(ecommerce).visual?.productPageWidth })
      }, [
        _cache[23] || (_cache[23] = _createElementVNode("h3", { class: "border-b border-gray-200 font-roboto text-gray-800 pb-3 font-medium" }, " Detalhes do produto ", -1)),
        _createElementVNode("div", _hoisted_31, [
          (_unref(ecommerce).visual?.displayBottomProductDescription)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                style: _normalizeStyle({ fontFamily: _unref(ecommerce).visual?.productDefaultFontFamily + ' !important' }),
                class: "text-gray-600",
                innerHTML: product.value?.description
              }, null, 12, _hoisted_32))
            : _createCommentVNode("", true),
          _createElementVNode("table", _hoisted_33, [
            (product.value?.variants[0].dimensions)
              ? (_openBlock(), _createElementBlock("tr", _hoisted_34, [
                  _cache[18] || (_cache[18] = _createElementVNode("th", { class: "py-2 px-4 border border-gray-300 w-40 font-medium" }, " Dimensões ", -1)),
                  _createElementVNode("th", _hoisted_35, _toDisplayString(product.value?.variants[0].dimensions), 1)
                ]))
              : _createCommentVNode("", true),
            (product.value?.variants[0].width)
              ? (_openBlock(), _createElementBlock("tr", _hoisted_36, [
                  _cache[19] || (_cache[19] = _createElementVNode("th", { class: "py-2 px-4 border border-gray-300 w-40 font-medium" }, " Largura ", -1)),
                  _createElementVNode("th", _hoisted_37, _toDisplayString(product.value?.variants[0].width), 1)
                ]))
              : _createCommentVNode("", true),
            (product.value?.variants[0].height)
              ? (_openBlock(), _createElementBlock("tr", _hoisted_38, [
                  _cache[20] || (_cache[20] = _createElementVNode("th", { class: "py-2 px-4 border border-gray-300 w-40 font-medium" }, " Altura ", -1)),
                  _createElementVNode("th", _hoisted_39, _toDisplayString(product.value?.variants[0].height), 1)
                ]))
              : _createCommentVNode("", true),
            (product.value?.variants[0].weight)
              ? (_openBlock(), _createElementBlock("tr", _hoisted_40, [
                  _cache[21] || (_cache[21] = _createElementVNode("th", { class: "py-2 px-4 border border-gray-300 w-40 font-medium" }, " Peso ", -1)),
                  _createElementVNode("th", _hoisted_41, _toDisplayString(product.value.variants[0].weight), 1)
                ]))
              : _createCommentVNode("", true),
            (product.value?.variants[0].quantityStock && _unref(ecommerce).visual?.displayBottomProductStock)
              ? (_openBlock(), _createElementBlock("tr", _hoisted_42, [
                  _cache[22] || (_cache[22] = _createElementVNode("th", { class: "py-2 px-4 border border-gray-300 w-40 font-medium" }, " Estoque ", -1)),
                  _createElementVNode("th", _hoisted_43, _toDisplayString(product.value.variants[0].quantityStock), 1)
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ], 4),
      _createElementVNode("div", {
        class: "pb-8 flex flex-col gap-2 mx-auto px-4 sm:px-0",
        ref_key: "reviewsListComponent",
        ref: reviewsListComponent,
        style: _normalizeStyle({ maxWidth: _unref(ecommerce).visual?.productPageWidth })
      }, [
        _createElementVNode("div", _hoisted_44, [
          (reviews.value?.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_45, [
                _createElementVNode("div", _hoisted_46, [
                  _createElementVNode("span", _hoisted_47, _toDisplayString(averageRating.value), 1),
                  _createVNode(RatingInput, {
                    value: roundedAverageRating.value,
                    name: 'productRating',
                    id: 'productRating',
                    disabled: true
                  }, null, 8, ["value"])
                ]),
                _createElementVNode("h4", _hoisted_48, _toDisplayString(reviews.value?.length) + " avaliações", 1)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("button", {
            class: "border border-[#000] inline py-3 px-4 text-[12px] sm: text-md",
            onClick: _cache[4] || (_cache[4] = ($event: any) => (isReviewFormOpen.value = !isReviewFormOpen.value))
          }, "Escrever avaliação")
        ]),
        _createElementVNode("div", {
          class: _normalizeClass([
        'grid transition-[grid-template-rows] duration-300 ease-in-out',
        isReviewFormOpen.value ? 'grid-rows-[1fr]' : 'grid-rows-[0fr] opacity-0'
      ])
        }, [
          _createElementVNode("div", _hoisted_49, [
            _cache[27] || (_cache[27] = _createElementVNode("h3", { class: "text-xl font-medium" }, "Adicione uma avaliação", -1)),
            _createVNode(RatingInput, {
              modelValue: reviewPayload.value.rating,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((reviewPayload.value.rating) = $event)),
              name: 'reviewPayloadRating',
              id: 'reviewPayloadRating'
            }, null, 8, ["modelValue"]),
            _createElementVNode("form", {
              onSubmit: _withModifiers(createReview, ["prevent"]),
              class: "grid grid-cols-2 gap-4 md:w-[750px] lg:w-[950px]"
            }, [
              _withDirectives(_createElementVNode("input", {
                type: "text",
                placeholder: "Nome",
                class: "rounded-lg border border-gray-300 col-span-1",
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((reviewPayload.value.name) = $event))
              }, null, 512), [
                [_vModelText, reviewPayload.value.name]
              ]),
              _withDirectives(_createElementVNode("input", {
                type: "email",
                placeholder: "Email",
                class: "rounded-lg border border-gray-300 col-span-1",
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((reviewPayload.value.email) = $event))
              }, null, 512), [
                [_vModelText, reviewPayload.value.email]
              ]),
              _withDirectives(_createElementVNode("textarea", {
                placeholder: "Digite o que achou do produto",
                class: "w-full h-[200px] p-4 rounded-lg border border-gray-300 col-span-2",
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((reviewPayload.value.comment) = $event))
              }, null, 512), [
                [_vModelText, reviewPayload.value.comment]
              ]),
              _createElementVNode("div", _hoisted_50, [
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList([0,1,2,3,4], (i) => {
                  return _createElementVNode("label", {
                    for: `review-media-${i}`,
                    key: i,
                    class: "border border-dashed p-4 h-36 w-full rounded-xl flex items-center justify-center flex-col border-2 border-zinc-300 gap-4 col-span-10 sm:col-span-5 lg:col-span-2",
                    style: _normalizeStyle({
                  backgroundImage: reviewPayload.value.media && reviewPayload.value.media[i] ? `url(${reviewPayload.value.media[i]})` : 'none',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center'
                })
                  }, [
                    (imageLoaders.value[i].isLoading)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createVNode(SpinnerLoader),
                          _createElementVNode("p", null, _toDisplayString(imageLoaders.value[i].progress) + "%", 1)
                        ], 64))
                      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          (!(reviewPayload.value.media && reviewPayload.value.media[i]))
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                _cache[24] || (_cache[24] = _createElementVNode("svg", {
                                  xmlns: "http://www.w3.org/2000/svg",
                                  width: "25",
                                  height: "24",
                                  viewBox: "0 0 25 24",
                                  fill: "none"
                                }, [
                                  _createElementVNode("path", {
                                    d: "M9.5 10C10.6046 10 11.5 9.10457 11.5 8C11.5 6.89543 10.6046 6 9.5 6C8.39543 6 7.5 6.89543 7.5 8C7.5 9.10457 8.39543 10 9.5 10Z",
                                    stroke: "#171717",
                                    "stroke-width": "1.5",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round"
                                  }),
                                  _createElementVNode("path", {
                                    d: "M13.5 2H9.5C4.5 2 2.5 4 2.5 9V15C2.5 20 4.5 22 9.5 22H15.5C20.5 22 22.5 20 22.5 15V10",
                                    stroke: "#171717",
                                    "stroke-width": "1.5",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round"
                                  }),
                                  _createElementVNode("path", {
                                    d: "M16.25 5H21.75",
                                    stroke: "#171717",
                                    "stroke-width": "1.5",
                                    "stroke-linecap": "round"
                                  }),
                                  _createElementVNode("path", {
                                    d: "M19 7.75V2.25",
                                    stroke: "#171717",
                                    "stroke-width": "1.5",
                                    "stroke-linecap": "round"
                                  }),
                                  _createElementVNode("path", {
                                    d: "M3.17 18.9501L8.1 15.6401C8.89 15.1101 10.03 15.1701 10.74 15.7801L11.07 16.0701C11.85 16.7401 13.11 16.7401 13.89 16.0701L18.05 12.5001C18.83 11.8301 20.09 11.8301 20.87 12.5001L22.5 13.9001",
                                    stroke: "#171717",
                                    "stroke-width": "1.5",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round"
                                  })
                                ], -1)),
                                _cache[25] || (_cache[25] = _createElementVNode("p", { class: "text-sm font-medium" }, "Selecionar imagem", -1))
                              ], 64))
                            : _createCommentVNode("", true),
                          _createElementVNode("input", {
                            type: "file",
                            hidden: "",
                            id: `review-media-${i}`,
                            onChange: (e) => uploadReviewImage(e, i)
                          }, null, 40, _hoisted_52)
                        ], 64))
                  ], 12, _hoisted_51)
                }), 64))
              ]),
              _createElementVNode("button", {
                class: "bg-primary py-3 rounded-xl text-white col-span-2 flex items-center justify-center",
                disabled: !reviewPayload.value.name || !reviewPayload.value.email || !reviewPayload.value.comment || !reviewPayload.value.rating
              }, [
                (!isCreatingReview.value)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createTextVNode("Enviar")
                    ], 64))
                  : (_openBlock(), _createElementBlock("div", _hoisted_54, _cache[26] || (_cache[26] = [
                      _createElementVNode("svg", {
                        "aria-hidden": "true",
                        class: "w-6 h-6 animate-spin fill-zinc-500",
                        viewBox: "0 0 100 101",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg"
                      }, [
                        _createElementVNode("path", {
                          d: "M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z",
                          fill: "currentColor"
                        }),
                        _createElementVNode("path", {
                          d: "M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z",
                          fill: "currentFill"
                        })
                      ], -1),
                      _createElementVNode("span", { class: "sr-only" }, "Loading...", -1)
                    ])))
              ], 8, _hoisted_53)
            ], 32)
          ])
        ], 2)
      ], 4),
      (product.value)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "mx-auto pb-16 px-4 sm:px-0",
            style: _normalizeStyle({ maxWidth: _unref(ecommerce).visual?.productPageWidth })
          }, [
            _createElementVNode("div", _hoisted_55, [
              (reviews.value && reviews.value?.length)
                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(reviews.value, (review) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: _normalizeClass(["break-inside-avoid-column mb-4 border rounded-xl review w-full inline-block", { 'cursor-pointer': !!review.media.length }]),
                      key: review.id,
                      onClick: ($event: any) => {selectedReview.value = (!!review.media.length ? review : undefined); isShowReviewModalOpen.value = !!review.media.length}
                    }, [
                      _createElementVNode("div", _hoisted_57, [
                        _createElementVNode("div", _hoisted_58, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(review.media.filter(m => (m as ReviewMedia).isThumb), (media, index) => {
                            return (_openBlock(), _createElementBlock(_Fragment, {
                              key: (media as ReviewMedia).id
                            }, [
                              (index < 1)
                                ? (_openBlock(), _createElementBlock("img", {
                                    key: 0,
                                    class: "w-full h-[200px] object-cover rounded-tl-xl rounded-tr-xl",
                                    src: (media as ReviewMedia).source,
                                    alt: ""
                                  }, null, 8, _hoisted_59))
                                : _createCommentVNode("", true)
                            ], 64))
                          }), 128))
                        ]),
                        _createElementVNode("div", _hoisted_60, [
                          _createElementVNode("div", _hoisted_61, [
                            _createElementVNode("span", _hoisted_62, _toDisplayString(review.name), 1)
                          ]),
                          _createElementVNode("div", _hoisted_63, [
                            _createVNode(RatingInput, {
                              value: review.rating!,
                              name: 'reviewRating' + review.id,
                              id: 'reviewRating' + review.id,
                              disabled: true
                            }, null, 8, ["value", "name", "id"])
                          ]),
                          _createElementVNode("p", _hoisted_64, " Avaliado em " + _toDisplayString(new Date(review.createdAt).toLocaleDateString('pt-BR')), 1),
                          _createElementVNode("p", _hoisted_65, _toDisplayString(review.comment), 1)
                        ])
                      ])
                    ], 10, _hoisted_56))
                  }), 128))
                : _createCommentVNode("", true)
            ])
          ], 4))
        : _createCommentVNode("", true),
      _createVNode(ShowReviewModal, {
        review: selectedReview.value,
        open: isShowReviewModalOpen.value,
        "onUpdate:open": _cache[9] || (_cache[9] = ($event: any) => ((isShowReviewModalOpen).value = $event))
      }, null, 8, ["review", "open"]),
      (relatedProducts.value.length)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "pb-16 mx-auto",
            style: _normalizeStyle({ maxWidth: _unref(ecommerce).visual?.productPageWidth })
          }, [
            _createElementVNode("h2", {
              class: "text-2xl font-medium text-gray-800 mb-6",
              style: _normalizeStyle({ fontSize: _unref(ecommerce).visual?.productSectionTitleFontSize, fontFamily: _unref(ecommerce).visual?.productSectionTitleFontFamily, fontWeight: _unref(ecommerce).visual?.productSectionTitleFontWeight, color: _unref(ecommerce).visual?.colorProductSectionTitle })
            }, " Produtos Relacionados ", 4),
            _createVNode(ProductSection, { products: relatedProducts.value }, null, 8, ["products"])
          ], 4))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})