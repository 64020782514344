import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vModelDynamic as _vModelDynamic, normalizeClass as _normalizeClass, withDirectives as _withDirectives, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50" }
const _hoisted_2 = { class: "bg-white rounded-lg p-8 w-full max-w-md mx-4 shadow-xl" }
const _hoisted_3 = { class: "relative" }
const _hoisted_4 = ["type"]
const _hoisted_5 = {
  key: 0,
  class: "mt-2 text-sm text-red-600"
}
const _hoisted_6 = ["disabled"]
const _hoisted_7 = {
  key: 0,
  class: "flex items-center justify-center"
}
const _hoisted_8 = { key: 1 }

import { useEcommerce } from '@/composables/useEcommerce'
import router from '@/router'
import { ref } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'PasswordView',
  setup(__props) {

const password = ref('')
const showPassword = ref(false)
const error = ref('')
const isLoading = ref(false)
const { ecommerce } = useEcommerce()

const handleSubmit = async () => {
  try {
    error.value = ''
    isLoading.value = true
    
    // Simulating API call to validate store password
    await new Promise(resolve => setTimeout(resolve, 1000))
    
    if (password.value !== ecommerce.value.password) { // Replace with actual validation
      throw new Error('Senha incorreta. Por favor, tente novamente.')
    }

    localStorage.setItem('password', password.value)
    
    router.push({ name: 'home' })
    
  } catch (err) {
    error.value = err instanceof Error ? err.message : 'Ocorreu um erro. Tente novamente.'
  } finally {
    isLoading.value = false
  }
}

return (_ctx: any,_cache: any) => {
  const _component_EyeIcon = _resolveComponent("EyeIcon")!
  const _component_EyeOffIcon = _resolveComponent("EyeOffIcon")!
  const _component_LoaderIcon = _resolveComponent("LoaderIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[4] || (_cache[4] = _createElementVNode("h2", { class: "text-2xl font-bold text-gray-900 mb-6 text-center" }, "Loja em Teste", -1)),
      _createElementVNode("form", {
        onSubmit: _withModifiers(handleSubmit, ["prevent"]),
        class: "space-y-6"
      }, [
        _createElementVNode("div", null, [
          _cache[2] || (_cache[2] = _createElementVNode("label", {
            for: "password",
            class: "block text-sm font-medium text-gray-700 mb-2"
          }, " Digite a senha da loja ", -1)),
          _createElementVNode("div", _hoisted_3, [
            _withDirectives(_createElementVNode("input", {
              type: showPassword.value ? 'text' : 'password',
              id: "password",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((password).value = $event)),
              class: _normalizeClass(["block w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-colors", { 'border-red-500': error.value }]),
              placeholder: "Digite a senha",
              required: ""
            }, null, 10, _hoisted_4), [
              [_vModelDynamic, password.value]
            ]),
            _createElementVNode("button", {
              type: "button",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (showPassword.value = !showPassword.value)),
              class: "absolute right-3 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700"
            }, [
              (!showPassword.value)
                ? (_openBlock(), _createBlock(_component_EyeIcon, {
                    key: 0,
                    class: "h-5 w-5"
                  }))
                : (_openBlock(), _createBlock(_component_EyeOffIcon, {
                    key: 1,
                    class: "h-5 w-5"
                  }))
            ])
          ]),
          (error.value)
            ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(error.value), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("button", {
          type: "submit",
          class: "w-full bg-blue-600 text-white py-3 px-4 rounded-lg font-medium hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors",
          disabled: isLoading.value
        }, [
          (isLoading.value)
            ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
                _createVNode(_component_LoaderIcon, { class: "animate-spin h-5 w-5 mr-2" }),
                _cache[3] || (_cache[3] = _createTextVNode(" Verificando... "))
              ]))
            : (_openBlock(), _createElementBlock("span", _hoisted_8, "Acessar Loja"))
        ], 8, _hoisted_6)
      ], 32)
    ])
  ]))
}
}

})