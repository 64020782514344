<template>
  <div class="grid grid-cols-1 gap-6 product-section" :class="[`grid-cols-${ecommerce.visual?.productsPerRow ?? 3}`]">
    <RouterLink :style="{ borderRadius: ecommerce.visual?.productThumbnailBorderRadius, maxHeight: ecommerce.visual?.productThumbnailHeight }" :to="{ name: 'product', params: { slug: generateProductSlug(product.title) } }" :class="[ecommerce.visual?.displayProductBorder ? 'shadow': '']" class="bg-white rounded overflow-hidden group" v-for="product in products">
      <div class="relative">
        <img
          :src="product.images?.length ? product.images[0].source : '/assets/images/no-image.png'"
          alt="product 1"
          class="w-full object-contain"
          @error="($event.target as HTMLImageElement).src = '/assets/images/no-image.png'"
        />
        <div
          class="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center gap-2 opacity-0 group-hover:opacity-100 transition"
        >
          <!-- <a
            href="#"
            class="text-white text-lg w-9 h-8 rounded-full bg-primary flex items-center justify-center hover:bg-gray-800 transition"
            title="view product"
          >
            <i class="fa-solid fa-magnifying-glass"></i>
          </a>
          <a
            href="#"
            class="text-white text-lg w-9 h-8 rounded-full bg-primary flex items-center justify-center hover:bg-gray-800 transition"
            title="add to wishlist"
          >
            <i class="fa-solid fa-heart"></i>
          </a> -->
        </div>
      </div>
      <div class="pt-4 pb-3 px-4">
        <a href="#">
          <h4
            class="font-medium text-xl mb-2 text-gray-800 hover:text-primary transition"
            :style="{ fontFamily: ecommerce.visual?.productThumbnailTitleFontFamily, fontSize: ecommerce.visual?.productThumbnailTitleFontSize, fontWeight: ecommerce.visual?.productThumbnailTitleFontWeight, color: ecommerce.visual?.colorProductThumbnailTitle }"
          >
            {{ product.title }}
          </h4>
        </a>
        <div class="flex items-baseline mb-1 space-x-2 flex-wrap">
          <template v-if="product.variants[0].promotionalPrice">
            <p class="text-xl text-primary font-semibold" :style="{ color: ecommerce.visual?.colorProductThumbnailPrice, fontSize: ecommerce.visual?.productThumbnailPriceFontSize }">{{ product.variants[0].promotionalPrice.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</p>
            <p class="text-sm text-gray-400 line-through">{{ product.variants[0].price?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</p>
          </template>
          <template v-else>
            <p class="text-xl text-primary font-semibold" :style="{ color: ecommerce.visual?.colorProductThumbnailPrice, fontSize: ecommerce.visual?.productThumbnailPriceFontSize }">{{ product.variants[0].price?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</p>
          </template>
        </div>
        <!-- <div class="flex items-center">
          <div class="flex gap-1 text-sm text-yellow-400">
            <span><i class="fa-solid fa-star"></i></span>
            <span><i class="fa-solid fa-star"></i></span>
            <span><i class="fa-solid fa-star"></i></span>
            <span><i class="fa-solid fa-star"></i></span>
            <span><i class="fa-solid fa-star"></i></span>
          </div>
          <div class="text-xs text-gray-500 ml-3">(150)</div>
        </div> -->
      </div>
      <a
      v-if="ecommerce.visual?.displayShowProductButton"
        href="#"
        class="block w-full py-1 text-center text-white bg-primary border border-primary rounded-b hover:bg-transparent hover:text-primary transition flex items-center justify-center"
        :style="{ height: ecommerce.visual?.showProductButtonHeight, background: ecommerce.visual?.colorShowProductButtonBackground, color: ecommerce.visual?.colorShowProductButtonText }"
        >Ver mais</a
      >
    </RouterLink>
  </div>
</template>

<script setup lang="ts">
import { useEcommerce } from '@/composables/useEcommerce';
import { Product } from '@/interfaces/products/Product';
import { generateProductSlug } from '@/interfaces/products/Product';

const { ecommerce } = useEcommerce()
defineProps<{ products: Product[] }>()
</script>

<style lang="scss">
@media screen and (max-width: 1000px) {
  .product-section {
    grid-template-columns: repeat(v-bind("(ecommerce.visual?.productsPerRow ?? 3) - 1"), 1fr);
  }
}

@media screen and (max-width: 900px) {
  .product-section {
    grid-template-columns: repeat(v-bind("(ecommerce.visual?.productsPerRow ?? 3) - 2"), 1fr);
  }
}


@media screen and (max-width: 700px) {
  .product-section {
    grid-template-columns: v-bind("ecommerce.visual?.mobileProductsPerRow === 2 ? '1fr 1fr' : '1fr'");
  }
}
</style>