import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, withModifiers as _withModifiers, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]

import { watch } from 'vue'

interface ModalProps {
  open: boolean;
  height?: string;
  width?: string;
  zIndex?: number;
  class?: string;
  id?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Modal',
  props: {
    open: { type: Boolean },
    height: {},
    width: {},
    zIndex: {},
    class: {},
    id: {}
  },
  emits: ['update:open'],
  setup(__props: any) {

const props = __props


watch(() => props.open, (newValue) => {
  if (newValue) {
    document.body.style.overflow = 'hidden'
  } else {
    document.body.style.overflow = ''
  }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:open', false))),
    class: _normalizeClass(["modal", [_ctx.open ? ('open ' + (props.class ?? '')) : ('closed ' + (props.class ?? ''))]]),
    style: _normalizeStyle({zIndex:(_ctx.zIndex ?? 2) - 1})
  }, [
    _createElementVNode("div", {
      onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"])),
      style: _normalizeStyle({ maxHeight: _ctx.height, maxWidth: _ctx.width, zIndex: _ctx.zIndex}),
      id: _ctx.id ? _ctx.id:'',
      class: _normalizeClass(["modal__content", [_ctx.open ? ('open ') : ('closed ')]])
    }, [
      _createElementVNode("button", {
        class: "close-modal",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:open', false)))
      }, _cache[3] || (_cache[3] = [
        _createStaticVNode("<figure data-v-0c751384><svg xmlns=\"http://www.w3.org/2000/svg\" width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" fill=\"none\" data-v-0c751384><g clip-path=\"url(#clip0_198_1747)\" data-v-0c751384><path d=\"M9.29404 7.89904L15.5 1.68604C15.6857 1.50039 15.79 1.24859 15.79 0.986043C15.79 0.723492 15.6857 0.471695 15.5 0.286043C15.3144 0.100392 15.0626 -0.00390625 14.8 -0.00390625C14.5375 -0.00390625 14.2857 0.100392 14.1 0.286043L7.90004 6.49904L1.68604 0.289043C1.50039 0.103392 1.24859 -0.00090625 0.986043 -0.00090625C0.723492 -0.00090625 0.471695 0.103392 0.286043 0.289043C0.100392 0.474695 -0.00390625 0.726492 -0.00390625 0.989043C-0.00390625 1.25159 0.100392 1.50339 0.286043 1.68904L6.50004 7.89904L0.289043 14.107C0.103392 14.2927 -0.00090625 14.5445 -0.00090625 14.807C-0.00090625 15.0696 0.103392 15.3214 0.289043 15.507C0.474695 15.6927 0.726492 15.797 0.989043 15.797C1.25159 15.797 1.50339 15.6927 1.68904 15.507L7.90004 9.29304L14.108 15.499C14.2937 15.6847 14.5455 15.789 14.808 15.789C15.0706 15.789 15.3224 15.6847 15.508 15.499C15.6937 15.3134 15.798 15.0616 15.798 14.799C15.798 14.5365 15.6937 14.2847 15.508 14.099L9.29404 7.89904Z\" data-v-0c751384></path></g><defs data-v-0c751384><clipPath id=\"clip0_198_1747\" data-v-0c751384><rect width=\"15.794\" height=\"15.793\" fill=\"white\" data-v-0c751384></rect></clipPath></defs></svg></figure>", 1)
      ])),
      _renderSlot(_ctx.$slots, "default")
    ], 14, _hoisted_1)
  ], 6))
}
}

})