import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, renderList as _renderList, Fragment as _Fragment, vModelRadio as _vModelRadio, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, vModelSelect as _vModelSelect, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container grid md:grid-cols-4 grid-cols-2 gap-6 pt-4 pb-16 items-start" }
const _hoisted_2 = { class: "text-center md:hidden" }
const _hoisted_3 = { class: "divide-y divide-gray-200 space-y-5" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "space-y-2" }
const _hoisted_6 = { class: "flex items-center" }
const _hoisted_7 = ["id", "value"]
const _hoisted_8 = ["for"]
const _hoisted_9 = { class: "ml-auto text-gray-600 text-sm" }
const _hoisted_10 = {
  key: 1,
  class: "pt-4"
}
const _hoisted_11 = { class: "space-y-2" }
const _hoisted_12 = { class: "flex items-center" }
const _hoisted_13 = ["id", "value"]
const _hoisted_14 = ["for"]
const _hoisted_15 = { class: "pt-4" }
const _hoisted_16 = { class: "mt-4 flex items-center" }
const _hoisted_17 = { class: "col-span-1 bg-white px-4 pb-6 shadow rounded overflow-hiddenb hidden md:block" }
const _hoisted_18 = { class: "divide-y divide-gray-200 space-y-5" }
const _hoisted_19 = { key: 0 }
const _hoisted_20 = { class: "text-xl text-gray-800 mb-3 uppercase font-medium" }
const _hoisted_21 = { key: 1 }
const _hoisted_22 = { class: "space-y-2" }
const _hoisted_23 = { class: "flex items-center" }
const _hoisted_24 = ["id", "value"]
const _hoisted_25 = ["for"]
const _hoisted_26 = { class: "ml-auto text-gray-600 text-sm" }
const _hoisted_27 = {
  key: 2,
  class: "pt-4"
}
const _hoisted_28 = { class: "space-y-2" }
const _hoisted_29 = { class: "flex items-center" }
const _hoisted_30 = ["id", "value"]
const _hoisted_31 = ["for"]
const _hoisted_32 = { class: "pt-4" }
const _hoisted_33 = { class: "mt-4 flex items-center" }
const _hoisted_34 = { class: "col-span-3" }
const _hoisted_35 = { class: "flex items-center mb-4" }
const _hoisted_36 = { class: "grid lg:grid-cols-3 sm:grid-cols-2 gap-6" }
const _hoisted_37 = {
  key: 0,
  class: "flex flex-col justify-center items-center col-span-12 min-h-80"
}

import PinkThemeLayout from "../layouts/PinkThemeLayout.vue";
import { useCategory } from '@/composables/useCategory';
import { useCollection } from '@/composables/useCollection';
import { useProduct } from "@/composables/useProduct";
import { computed, ref } from "vue";
import { useRoute } from "vue-router";
import { generateProductSlug } from '@/interfaces/products/Product';
import router from "@/router";
import ProductSection from "@/components/sections/ProductSection.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'PinkThemeShopView',
  setup(__props) {

const route = useRoute();
const { products } = useProduct()
const { categories } = useCategory()
const { collections } = useCollection()

const category = computed(() => categories.value.find(c => c.slug === route.params.slug))
const collection = computed(() => collections.value.find((c) => c.slug === route.params.slug))

const filter = ref({ 
  categoryId: null, 
  collectionId: null, 
  orderBy: '', 
  minPrice: null,
  maxPrice: null, 
})
const isMobileSidebarOpen = ref(false)
const visibleProducts = computed(() => {
  const selectedCollection = filter.value.collectionId
    ? collections.value.find((c) => c.id === filter.value.collectionId)
    : null;

  const filteredProducts = products.value?.filter((p) => {
    const hasCategory = p.categoryId === filter.value.categoryId || !filter.value.categoryId
    const matchesSearch = route.query.search ? p.title.toLowerCase().includes(String(route.query.search).toLowerCase()) : true
    
    const withinPriceRange = (
      (!filter.value.minPrice || (p.variants[0].promotionalPrice ?? p.variants[0].price)! >= filter.value.minPrice) &&
      (!filter.value.maxPrice || (p.variants[0].promotionalPrice ?? p.variants[0].price)! <= filter.value.maxPrice)
    )

    const inSelectedCollection = selectedCollection
      ? selectedCollection.productIds.includes(p.id)
      : true;

    if (!route.params.slug) {
      return matchesSearch && hasCategory && withinPriceRange && inSelectedCollection
    } else if (route.path.includes('categories')) {
      if (!category) {
        router.push({ name: 'not-found' })
      }
  
      return category.value?.productIds.includes(p.id) && withinPriceRange
    } else if (route.path.includes('collections')) {
      if (!collection) {
        router.push({ name: 'not-found' })
      }

      return collection.value?.productIds.includes(p.id) && withinPriceRange
    }
  }) ?? []

  if (filter.value.orderBy === 'priceAsc') {
    return filteredProducts.sort((a, b) => a.variants[0].price! - b.variants[0].price!)
  } else if (filter.value.orderBy === 'priceDesc') {
    return filteredProducts.sort((a, b) => b.variants[0].price! - a.variants[0].price!)
  } else if (filter.value.orderBy === 'latest') {
    return filteredProducts.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
  }

  return filteredProducts
})
const pageTitle = computed(() => {
  if (collection.value) {
    return collection.value.title
  }
  if (category.value) {
    return category.value.title
  }
})

function cleanFilter() {
  filter.value = { 
    categoryId: null, 
    collectionId: null, 
    orderBy: '', 
    minPrice: null,
    maxPrice: null, 
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(PinkThemeLayout, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("button", {
            class: "text-white hover:bg-blue-100 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 mr-2 mb-2 border-2 focus:outline-none dark:focus:ring-blue-800 block md:hidden",
            type: "button",
            "data-drawer-target": "drawer-example",
            "data-drawer-show": "drawer-example",
            "aria-controls": "drawer-example",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (isMobileSidebarOpen.value = true))
          }, _cache[11] || (_cache[11] = [
            _createElementVNode("svg", {
              xmlns: "http://www.w3.org/2000/svg",
              width: "24",
              height: "24",
              viewBox: "0 0 24 24",
              fill: "none"
            }, [
              _createElementVNode("path", {
                d: "M20.5999 4.1001V6.3001C20.5999 7.1001 20.0999 8.1001 19.5999 8.6001L15.2999 12.4001C14.6999 12.9001 14.2999 13.9001 14.2999 14.7001V19.0001C14.2999 19.6001 13.8999 20.4001 13.3999 20.7001L11.9999 21.6001C10.6999 22.4001 8.89988 21.5001 8.89988 19.9001V14.6001C8.89988 13.9001 8.49989 13.0001 8.09989 12.5001L7.62989 12.0101C7.31989 11.6801 7.25989 11.1801 7.50989 10.7901L12.6299 2.5701C12.8099 2.2801 13.1299 2.1001 13.4799 2.1001H18.5999C19.6999 2.1001 20.5999 3.0001 20.5999 4.1001Z",
                fill: "#171717"
              }),
              _createElementVNode("path", {
                d: "M10.3499 3.6301L6.7999 9.3201C6.4599 9.8701 5.6799 9.9501 5.2299 9.4801L4.2999 8.5001C3.7999 8.0001 3.3999 7.1001 3.3999 6.5001V4.2001C3.3999 3.0001 4.2999 2.1001 5.3999 2.1001H9.4999C10.2799 2.1001 10.7599 2.9601 10.3499 3.6301Z",
                fill: "#171717"
              })
            ], -1)
          ]))
        ]),
        _createElementVNode("div", {
          id: "drawer-example",
          class: _normalizeClass(["fixed top-0 left-0 z-40 h-screen p-4 overflow-y-auto transition-transform bg-white w-80 bg-gray-800", isMobileSidebarOpen.value ? '' : '-translate-x-full']),
          tabindex: "-1",
          "aria-labelledby": "drawer-label"
        }, [
          _createElementVNode("button", {
            type: "button",
            "data-drawer-hide": "drawer-example",
            "aria-controls": "drawer-example",
            class: "text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (isMobileSidebarOpen.value = false))
          }, _cache[12] || (_cache[12] = [
            _createElementVNode("svg", {
              "aria-hidden": "true",
              class: "w-5 h-5",
              fill: "currentColor",
              viewBox: "0 0 20 20",
              xmlns: "http://www.w3.org/2000/svg"
            }, [
              _createElementVNode("path", {
                "fill-rule": "evenodd",
                d: "M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z",
                "clip-rule": "evenodd"
              })
            ], -1),
            _createElementVNode("span", { class: "sr-only" }, "Fechar menu", -1)
          ])),
          _createElementVNode("div", _hoisted_3, [
            (!_ctx.$route.params.slug)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _cache[13] || (_cache[13] = _createElementVNode("h3", { class: "text-xl text-gray-800 mb-3 uppercase font-medium" }, " Categorias ", -1)),
                  _createElementVNode("div", _hoisted_5, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(categories), (category) => {
                      return (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        _withDirectives(_createElementVNode("input", {
                          type: "radio",
                          name: "cat-1",
                          id: `cat-${category.id}`,
                          class: "text-primary focus:ring-0 rounded-full cursor-pointer",
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((filter.value.categoryId) = $event)),
                          value: category.id
                        }, null, 8, _hoisted_7), [
                          [_vModelRadio, filter.value.categoryId]
                        ]),
                        _createElementVNode("label", {
                          for: `cat-${category.id}`,
                          class: "text-gray-600 ml-3 cursor-pointer"
                        }, _toDisplayString(category.title), 9, _hoisted_8),
                        _createElementVNode("div", _hoisted_9, "(" + _toDisplayString(category.products?.length ?? 0) + ")", 1)
                      ]))
                    }), 256))
                  ])
                ]))
              : _createCommentVNode("", true),
            (!_ctx.$route.params.slug)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _cache[14] || (_cache[14] = _createElementVNode("h3", { class: "text-xl text-gray-800 mb-3 uppercase font-medium" }, " Coleções ", -1)),
                  _createElementVNode("div", _hoisted_11, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(collections), (collection) => {
                      return (_openBlock(), _createElementBlock("div", _hoisted_12, [
                        _withDirectives(_createElementVNode("input", {
                          type: "radio",
                          name: "brand-1",
                          id: `collection-${collection.id}`,
                          class: "text-primary focus:ring-0 rounded-full cursor-pointer",
                          value: collection.id,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((filter.value.collectionId) = $event))
                        }, null, 8, _hoisted_13), [
                          [_vModelRadio, filter.value.collectionId]
                        ]),
                        _createElementVNode("label", {
                          for: `collection-${collection.id}`,
                          class: "text-gray-600 ml-3 cursor-pointer"
                        }, _toDisplayString(collection.title), 9, _hoisted_14)
                      ]))
                    }), 256))
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_15, [
              _cache[16] || (_cache[16] = _createElementVNode("h3", { class: "text-xl text-gray-800 mb-3 uppercase font-medium" }, " Preço ", -1)),
              _createElementVNode("div", _hoisted_16, [
                _withDirectives(_createElementVNode("input", {
                  type: "number",
                  name: "min",
                  id: "min",
                  class: "w-full border-gray-300 focus:border-primary rounded focus:ring-0 px-3 py-1 text-gray-600 shadow-sm",
                  placeholder: "min",
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((filter.value.minPrice) = $event))
                }, null, 512), [
                  [_vModelText, filter.value.minPrice]
                ]),
                _cache[15] || (_cache[15] = _createElementVNode("span", { class: "mx-3 text-gray-500" }, "-", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "number",
                  name: "max",
                  id: "max",
                  class: "w-full border-gray-300 focus:border-primary rounded focus:ring-0 px-3 py-1 text-gray-600 shadow-sm",
                  placeholder: "max",
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((filter.value.maxPrice) = $event))
                }, null, 512), [
                  [_vModelText, filter.value.maxPrice]
                ])
              ])
            ])
          ])
        ], 2),
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("div", _hoisted_18, [
            (pageTitle.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                  _createElementVNode("h3", _hoisted_20, _toDisplayString(pageTitle.value), 1)
                ]))
              : _createCommentVNode("", true),
            (!_ctx.$route.params.slug)
              ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                  _cache[17] || (_cache[17] = _createElementVNode("h3", { class: "text-xl text-gray-800 mb-3 uppercase font-medium" }, " Categorias ", -1)),
                  _createElementVNode("div", _hoisted_22, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(categories), (category) => {
                      return (_openBlock(), _createElementBlock("div", _hoisted_23, [
                        _withDirectives(_createElementVNode("input", {
                          type: "radio",
                          name: "category-1",
                          id: `cat-${category.id}`,
                          class: "text-primary focus:ring-0 rounded-full cursor-pointer",
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((filter.value.categoryId) = $event)),
                          value: category.id
                        }, null, 8, _hoisted_24), [
                          [_vModelRadio, filter.value.categoryId]
                        ]),
                        _createElementVNode("label", {
                          for: `cat-${category.id}`,
                          class: "text-gray-600 ml-3 cursor-pointer"
                        }, _toDisplayString(category.title), 9, _hoisted_25),
                        _createElementVNode("div", _hoisted_26, "(" + _toDisplayString(category.products?.length ?? 0) + ")", 1)
                      ]))
                    }), 256))
                  ])
                ]))
              : _createCommentVNode("", true),
            (!_ctx.$route.params.slug)
              ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                  _cache[18] || (_cache[18] = _createElementVNode("h3", { class: "text-xl text-gray-800 mb-3 uppercase font-medium" }, " Coleções ", -1)),
                  _createElementVNode("div", _hoisted_28, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(collections), (collection) => {
                      return (_openBlock(), _createElementBlock("div", _hoisted_29, [
                        _withDirectives(_createElementVNode("input", {
                          type: "radio",
                          name: "brand-1",
                          id: `collection-${collection.id}`,
                          value: collection.id,
                          class: "text-primary focus:ring-0 rounded-full cursor-pointer",
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((filter.value.collectionId) = $event))
                        }, null, 8, _hoisted_30), [
                          [_vModelRadio, filter.value.collectionId]
                        ]),
                        _createElementVNode("label", {
                          for: `collection-${collection.id}`,
                          class: "text-gray-600 ml-3 cursor-pointer"
                        }, _toDisplayString(collection.title), 9, _hoisted_31)
                      ]))
                    }), 256))
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_32, [
              _cache[20] || (_cache[20] = _createElementVNode("h3", { class: "text-xl text-gray-800 mb-3 uppercase font-medium" }, " Preço ", -1)),
              _createElementVNode("div", _hoisted_33, [
                _withDirectives(_createElementVNode("input", {
                  type: "number",
                  name: "min",
                  id: "min",
                  class: "w-full border-gray-300 focus:border-primary rounded focus:ring-0 px-3 py-1 text-gray-600 shadow-sm",
                  placeholder: "min",
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((filter.value.minPrice) = $event))
                }, null, 512), [
                  [_vModelText, filter.value.minPrice]
                ]),
                _cache[19] || (_cache[19] = _createElementVNode("span", { class: "mx-3 text-gray-500" }, "-", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "number",
                  name: "max",
                  id: "max",
                  class: "w-full border-gray-300 focus:border-primary rounded focus:ring-0 px-3 py-1 text-gray-600 shadow-sm",
                  placeholder: "max",
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((filter.value.maxPrice) = $event))
                }, null, 512), [
                  [_vModelText, filter.value.maxPrice]
                ])
              ])
            ]),
            _createElementVNode("div", { class: "pt-4 flex items-center gap-2" }, [
              _cache[21] || (_cache[21] = _createElementVNode("svg", {
                xmlns: "http://www.w3.org/2000/svg",
                width: "24",
                height: "24",
                viewBox: "0 0 24 24",
                fill: "none"
              }, [
                _createElementVNode("path", {
                  d: "M12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22Z",
                  stroke: "#171717",
                  "stroke-width": "1.5",
                  "stroke-miterlimit": "10",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                  class: "stroke-zinc-400"
                }),
                _createElementVNode("path", {
                  d: "M18.8999 5L4.8999 19",
                  stroke: "#171717",
                  "stroke-width": "1.5",
                  "stroke-miterlimit": "10",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                  class: "stroke-zinc-500"
                })
              ], -1)),
              _createElementVNode("button", {
                class: "text-zinc-500",
                onClick: cleanFilter
              }, "Limpar filtros")
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_34, [
          _createElementVNode("div", _hoisted_35, [
            _withDirectives(_createElementVNode("select", {
              name: "sort",
              id: "sort",
              class: "w-64 text-sm text-gray-600 py-3 px-4 border-gray-300 shadow-sm rounded focus:ring-primary focus:border-primary",
              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((filter.value.orderBy) = $event))
            }, _cache[22] || (_cache[22] = [
              _createElementVNode("option", { value: "" }, "Ordem padrão", -1),
              _createElementVNode("option", { value: "priceAsc" }, "Preço baixo a preço alto", -1),
              _createElementVNode("option", { value: "priceDesc" }, "Preço alto a preço baixo", -1),
              _createElementVNode("option", { value: "latest" }, "Produtos mais recentes", -1)
            ]), 512), [
              [_vModelSelect, filter.value.orderBy]
            ])
          ]),
          _createElementVNode("div", _hoisted_36, [
            (!visibleProducts.value.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_37, _cache[23] || (_cache[23] = [
                  _createElementVNode("svg", {
                    width: "202",
                    height: "116",
                    xmlns: "http://www.w3.org/2000/svg",
                    viewBox: "0 0 16 16",
                    fill: "none"
                  }, [
                    _createElementVNode("path", {
                      d: "M0.0247006 7.87823C0.162935 9.46532 0.887052 10.9532 2.06688 12.0735C3.24671 13.1938 4.80475 13.8732 6.46033 13.9891C8.11592 14.1051 9.76012 13.6501 11.0972 12.7061L14.2375 15.7092C14.4312 15.8952 14.6938 15.9996 14.9677 15.9996C15.2415 15.9996 15.5043 15.8953 15.6978 15.7093C15.8915 15.5235 16.0002 15.2714 16 15.0085C16.0002 14.7458 15.8915 14.4937 15.698 14.3078L12.5681 11.2947C13.9283 9.5308 14.2998 7.23853 13.5616 5.1613C12.8236 3.08439 11.0702 1.48725 8.86994 0.888013C6.66989 0.288729 4.30337 0.763496 2.53789 2.15874C0.772543 3.55382 -0.16685 5.69131 0.0244658 7.87791L0.0247006 7.87823ZM6.96867 2.62946C8.26895 2.62946 9.51591 3.12511 10.4353 4.00726C11.3547 4.88941 11.8713 6.08607 11.8713 7.33363C11.8713 8.58119 11.3547 9.77768 10.4353 10.6598C9.51591 11.542 8.2689 12.0376 6.96867 12.0376C5.66844 12.0376 4.42124 11.542 3.50184 10.6598C2.58245 9.77768 2.06587 8.58119 2.06587 7.33363C2.06587 6.08607 2.58245 4.88941 3.50184 4.00726C4.42124 3.12511 5.66844 2.62946 6.96867 2.62946Z",
                      fill: "#676767"
                    })
                  ], -1),
                  _createElementVNode("p", { class: "font-medium pt-4" }, "Nenhum produto foi encontrado", -1)
                ])))
              : _createCommentVNode("", true)
          ]),
          _createVNode(ProductSection, { products: visibleProducts.value }, null, 8, ["products"])
        ])
      ])
    ]),
    _: 1
  }))
}
}

})