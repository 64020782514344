<template>
  <a 
    target="_blank" 
    :class="{
      'text-menu-hover': isActive,
      [props.class]: true
    }" 
    @click="redirectMenu($event, props.menu)" 
    :href="props.menu.href" 
    :key="props.menu.id"
  >
    {{ props.menu.title }}
  </a>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useCategory } from '@/composables/useCategory';
import { useCollection } from '@/composables/useCollection';
import { useEcommerce } from '@/composables/useEcommerce';
import { useMenu } from '@/composables/useMenu';
import { MenuTypeEnum } from '@/enums/ecommerce/menus/MenuTypeEnum';
import { Menu } from '@/interfaces/ecommerce/Menu';
import { useRoute } from 'vue-router';

const props = defineProps<{ menu: Menu; class: string }>();
const { ecommerce } = useEcommerce();
const { categories } = useCategory();
const { collections } = useCollection();
const { redirectMenu } = useMenu();
const route = useRoute()

const isActive = computed(() => {
  if (props.menu.type === MenuTypeEnum.PAGE) {
    return ecommerce.value.pages?.find((page) => page.id === props.menu.typeId)?.slug === route.path.slice(1);
  }

  if (props.menu.type === MenuTypeEnum.CATEGORY) {
    return (
      route.path.includes('categories') &&
      categories.value?.find((c) => c.id === props.menu.typeId)?.slug === route.params.slug
    );
  }

  if (props.menu.type === MenuTypeEnum.COLLECTION) {
    return (
      route.path.includes('collections') &&
      collections.value?.find((c) => c.id === props.menu.typeId)?.slug === route.params.slug
    );
  }

  return false;
});
</script>
