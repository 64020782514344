<template>
  <PinkThemeLayout>
    <!-- breadcrumb -->
    <!-- <div class="container py-4 flex items-center gap-3">
      <a href="index.html" class="text-primary text-base">
        <i class="fa-solid fa-house"></i>
      </a>
      <span class="text-sm text-gray-400">
        <i class="fa-solid fa-chevron-right"></i>
      </span>
      <p class="text-gray-600 font-medium">Product</p>
    </div> -->
    <!-- ./breadcrumb -->

    <!-- product-detail -->
    <div class="grid sm:grid-cols-2 gap-6 pt-4 mx-auto px-4 sm:px-0" :style="{ maxWidth: ecommerce.visual?.productPageWidth, fontFamily: ecommerce.visual?.productDefaultFontFamily }">
      <div>
        <img
          :src="selectedImage ? selectedImage : (product?.images?.length ? product.images[0].source : '/assets/images/no-image.png')"
          alt="product"
          class="w-full object-contain"
          @error="($event.target as HTMLImageElement).src = '/assets/images/no-image.png'"
          style="height: 30rem"
        />
        <div class="grid grid-cols-5 gap-4 mt-4">
          <img
            v-for="img in product?.images.filter(i => !i.isThumb)"
            :src="img.source"
            alt="product2"
            class="w-full cursor-pointer border rounded-md"
            :class="img.source === selectedImage ? 'border-primary' : ''"
            @click="selectedImage = img.source"
          />
          <template v-for="img in product?.variants.flatMap(v => v.images)">
            <img
              v-if="img && (!selectedVariant || (selectedVariant.variantId === img.variantId))"
              :src="img.source"
              alt="product2"
              class="w-full cursor-pointer border rounded-md"
              :class="{ 
                'opacity-04': selectedVariant && selectedVariant.id === img.variantId,
                'border-primary': img.source === selectedImage
              }"
              @click="selectedImage = img.source"
            />
          </template>
        </div>
      </div>

      <div>
        <h2 class="text-3xl font-medium mb-2 leading-[36px]" 
          :style="{ color: ecommerce.visual?.colorProductTitle, fontFamily: ecommerce.visual?.productTitleFontFamily, fontSize: ecommerce.visual?.productTitleFontSize, fontWeight: ecommerce.visual?.productTitleFontWeight }">
          {{ product?.title }}
        </h2>
        <div class="flex items-center mb-4" v-if="product?.reviews?.length">
          <div class="flex gap-1 text-sm text-yellow-400">
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 25 24" fill="none">
                <path d="M13.7457 3.51001L15.5057 7.03001C15.7457 7.52001 16.3857 7.99001 16.9257 8.08001L20.1157 8.61001C22.1557 8.95001 22.6357 10.43 21.1657 11.89L18.6857 14.37C18.2657 14.79 18.0357 15.6 18.1657 16.18L18.8757 19.25C19.4357 21.68 18.1457 22.62 15.9957 21.35L13.0057 19.58C12.4657 19.26 11.5757 19.26 11.0257 19.58L8.03566 21.35C5.89566 22.62 4.59566 21.67 5.15566 19.25L5.86566 16.18C5.99566 15.6 5.76566 14.79 5.34566 14.37L2.86566 11.89C1.40566 10.43 1.87566 8.95001 3.91566 8.61001L7.10566 8.08001C7.63566 7.99001 8.27566 7.52001 8.51566 7.03001L10.2757 3.51001C11.2357 1.60001 12.7957 1.60001 13.7457 3.51001Z" fill="#171717"/>
              </svg>
            </span>
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 25 24" fill="none">
                <path d="M13.7457 3.51001L15.5057 7.03001C15.7457 7.52001 16.3857 7.99001 16.9257 8.08001L20.1157 8.61001C22.1557 8.95001 22.6357 10.43 21.1657 11.89L18.6857 14.37C18.2657 14.79 18.0357 15.6 18.1657 16.18L18.8757 19.25C19.4357 21.68 18.1457 22.62 15.9957 21.35L13.0057 19.58C12.4657 19.26 11.5757 19.26 11.0257 19.58L8.03566 21.35C5.89566 22.62 4.59566 21.67 5.15566 19.25L5.86566 16.18C5.99566 15.6 5.76566 14.79 5.34566 14.37L2.86566 11.89C1.40566 10.43 1.87566 8.95001 3.91566 8.61001L7.10566 8.08001C7.63566 7.99001 8.27566 7.52001 8.51566 7.03001L10.2757 3.51001C11.2357 1.60001 12.7957 1.60001 13.7457 3.51001Z" fill="#171717"/>
              </svg>
            </span>
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 25 24" fill="none">
                <path d="M13.7457 3.51001L15.5057 7.03001C15.7457 7.52001 16.3857 7.99001 16.9257 8.08001L20.1157 8.61001C22.1557 8.95001 22.6357 10.43 21.1657 11.89L18.6857 14.37C18.2657 14.79 18.0357 15.6 18.1657 16.18L18.8757 19.25C19.4357 21.68 18.1457 22.62 15.9957 21.35L13.0057 19.58C12.4657 19.26 11.5757 19.26 11.0257 19.58L8.03566 21.35C5.89566 22.62 4.59566 21.67 5.15566 19.25L5.86566 16.18C5.99566 15.6 5.76566 14.79 5.34566 14.37L2.86566 11.89C1.40566 10.43 1.87566 8.95001 3.91566 8.61001L7.10566 8.08001C7.63566 7.99001 8.27566 7.52001 8.51566 7.03001L10.2757 3.51001C11.2357 1.60001 12.7957 1.60001 13.7457 3.51001Z" fill="#171717"/>
              </svg>
            </span>
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 25 24" fill="none">
                <path d="M13.7457 3.51001L15.5057 7.03001C15.7457 7.52001 16.3857 7.99001 16.9257 8.08001L20.1157 8.61001C22.1557 8.95001 22.6357 10.43 21.1657 11.89L18.6857 14.37C18.2657 14.79 18.0357 15.6 18.1657 16.18L18.8757 19.25C19.4357 21.68 18.1457 22.62 15.9957 21.35L13.0057 19.58C12.4657 19.26 11.5757 19.26 11.0257 19.58L8.03566 21.35C5.89566 22.62 4.59566 21.67 5.15566 19.25L5.86566 16.18C5.99566 15.6 5.76566 14.79 5.34566 14.37L2.86566 11.89C1.40566 10.43 1.87566 8.95001 3.91566 8.61001L7.10566 8.08001C7.63566 7.99001 8.27566 7.52001 8.51566 7.03001L10.2757 3.51001C11.2357 1.60001 12.7957 1.60001 13.7457 3.51001Z" fill="#171717"/>
              </svg>
            </span>
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 25 24" fill="none">
                <path d="M13.7457 3.51001L15.5057 7.03001C15.7457 7.52001 16.3857 7.99001 16.9257 8.08001L20.1157 8.61001C22.1557 8.95001 22.6357 10.43 21.1657 11.89L18.6857 14.37C18.2657 14.79 18.0357 15.6 18.1657 16.18L18.8757 19.25C19.4357 21.68 18.1457 22.62 15.9957 21.35L13.0057 19.58C12.4657 19.26 11.5757 19.26 11.0257 19.58L8.03566 21.35C5.89566 22.62 4.59566 21.67 5.15566 19.25L5.86566 16.18C5.99566 15.6 5.76566 14.79 5.34566 14.37L2.86566 11.89C1.40566 10.43 1.87566 8.95001 3.91566 8.61001L7.10566 8.08001C7.63566 7.99001 8.27566 7.52001 8.51566 7.03001L10.2757 3.51001C11.2357 1.60001 12.7957 1.60001 13.7457 3.51001Z" fill="#171717"/>
              </svg>
            </span>
          </div>
          <div class="text-xs text-gray-500 ml-3">({{ product?.reviews.length }} Avaliações)</div>
        </div>
        <div class="space-y-2">
          <p v-if="ecommerce.visual?.displayTopProductStock" class="text-gray-800 font-semibold space-x-2">
            <span>Disponibilidade: </span>
            <span class="text-green-600" v-if="product?.variants[0].quantityStock">Em estoque</span>
            <span class="text-red-600" v-else>Sem estoque</span>
          </p>
          <p v-if="ecommerce.visual?.displayTopProductCategory" class="space-x-2">
            <span class="text-gray-800 font-semibold">Categoria: </span>
            <span class="text-gray-600">{{ category?.title }}</span>
          </p>
        </div>
        <div class="flex items-baseline mb-1 space-x-2">
          <div v-if="product" class="flex items-baseline mb-1 space-x-2">
              <template v-if="!selectedVariant && product.variants[0].promotionalPrice">
                <p class="text-xl text-primary font-semibold">{{ product.variants[0].promotionalPrice.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</p>
                <p class="text-sm text-gray-400 line-through">{{ product.variants[0].price?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</p>
              </template>
              <template v-else-if="!selectedVariant">
                <p class="text-xl text-primary font-semibold">{{ product.variants[0].price?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</p>
              </template>
              <template v-else-if="selectedVariant.promotionalPrice">
                <p class="text-xl text-primary font-semibold">{{ selectedVariant.promotionalPrice.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</p>
                <p class="text-sm text-gray-400 line-through">{{ selectedVariant.price?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</p>
              </template>
              <template v-else>
                <p class="text-xl text-primary font-semibold">{{ selectedVariant.price?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</p>
              </template>
            </div>
        </div>

        <p v-if="ecommerce.visual?.displayTopProductDescription" :style="{ fontFamily: ecommerce.visual?.productDefaultFontFamily }" class="mt-4 text-gray-600" v-html="product?.description"></p>

        <div v-for="(attributeName, index) in attributeNames" :key="attributeName" class="pt-4">
          <h3 class="text-sm text-gray-800 uppercase mb-1">{{ attributeName }}</h3>
          <div class="flex items-center gap-2">
            <div class="size-selector" v-for="attribute in getAttributesByName(attributeName)" :key="attribute.value">
              <input 
                type="radio" 
                :name="attributeName" 
                :id="attributeName + attribute.value" 
                class="hidden variant-input" 
                :value="attribute.value" 
                @change="selectVariant" 
                :disabled="!attribute.isAvailable && index !== 0"
                :checked="attribute.checked"
              />
              <label
                :for="attributeName + attribute.value"
                class="text-xs border border-gray-200 rounded-sm p-2 min-h-6 min-w-6 flex items-center justify-center cursor-pointer shadow-sm text-gray-600"
                :style="{ backgroundColor: attribute.type === 'color' ? attribute.typeValue : 'unset' }"
              >
                {{ attribute.type === 'color' ? '' : attribute.value }}
              </label>
            </div>
          </div>
        </div>

        <div class="mt-4">
          <h3 class="text-sm text-gray-800 uppercase mb-1">Quantidade</h3>
          <div
            class="flex border border-gray-300 text-gray-600 divide-x divide-gray-300 w-max"
          >
            <button
              class="h-8 w-8 text-xl flex items-center justify-center cursor-pointer select-none"
              @click="quantity > 1 ? quantity-- : ''"
            >
              -
            </button>
            <div class="h-8 w-8 text-base flex items-center justify-center">
              {{ quantity }}
            </div>
            <button
              class="h-8 w-8 text-xl flex items-center justify-center cursor-pointer select-none"
              @click="quantity++"
            >
              +
          </button>
          </div>
        </div>

        <div class="mt-6 flex gap-3 pb-5 pt-5">
          <a
            href="#"
            class="bg-primary border border-primary text-white px-8 py-2 font-medium rounded uppercase flex items-center gap-2 hover:bg-transparent hover:text-primary transition"
            :style="{ 'color': ecommerce.visual?.colorAddProductButtonText, 'backgroundColor': ecommerce.visual?.colorAddProductButtonBackground }"
            @click.prevent="selectVariantByForm(); addIntoCart(selectedVariant?.uuid ?? product?.variants[0].uuid!, quantity);"
            :disabled="isAddingProductIntoCart || (!!product?.variants[0].quantityStock && product?.variants[0].quantityStock < 1)"          >
            <template v-if="!isAddingProductIntoCart">
              <template v-if="!product?.variants[0].quantityStock || product?.variants[0].quantityStock > 0">
                <i class="fa-solid fa-bag-shopping"></i> Adicionar ao carrinho
              </template>
              <template v-else>
                Produto indisponível
              </template>
            </template>
            <template v-else>
              <svg aria-hidden="true" class="w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-primary" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
              </svg>
              <span>Processando...</span>
            </template>
          </a>
          <!-- <a
            href="#"
            class="border border-gray-300 text-gray-600 px-8 py-2 font-medium rounded uppercase flex items-center gap-2 hover:text-primary transition"
          >
            <i class="fa-solid fa-heart"></i> Wishlist
          </a> -->
        </div>
      </div>
    </div>
    <!-- ./product-detail -->

    <!-- description -->
    <div class="pb-16 pt-8 mx-auto px-4 sm:px-0" :style="{ maxWidth: ecommerce.visual?.productPageWidth }">
      <h3
        class="border-b border-gray-200 font-roboto text-gray-800 pb-3 font-medium"
      >
        Detalhes do produto
      </h3>
      <div class="sm:w-3/5 pt-6">
        <div v-if="ecommerce.visual?.displayBottomProductDescription" :style="{ fontFamily: ecommerce.visual?.productDefaultFontFamily + ' !important' }" class="text-gray-600" v-html="product?.description"></div>

        <table
          class="table-auto border-collapse w-full text-left text-gray-600 text-sm mt-6"
        >
          <!-- <tr>
            <th class="py-2 px-4 border border-gray-300 w-40 font-medium">
              Color
            </th>
            <th class="py-2 px-4 border border-gray-300">Blank, Brown, Red</th>
          </tr> -->
          <tr v-if="product?.variants[0].dimensions">
            <th class="py-2 px-4 border border-gray-300 w-40 font-medium">
              Dimensões
            </th>
            <th class="py-2 px-4 border border-gray-300">{{ product?.variants[0].dimensions }}</th>
          </tr>
          <tr v-if="product?.variants[0].width">
            <th class="py-2 px-4 border border-gray-300 w-40 font-medium">
              Largura
            </th>
            <th class="py-2 px-4 border border-gray-300">{{ product?.variants[0].width }}</th>
          </tr>
          <tr v-if="product?.variants[0].height">
            <th class="py-2 px-4 border border-gray-300 w-40 font-medium">
              Altura
            </th>
            <th class="py-2 px-4 border border-gray-300">{{ product?.variants[0].height }}</th>
          </tr>
          <tr v-if="product?.variants[0].weight">
            <th class="py-2 px-4 border border-gray-300 w-40 font-medium">
              Peso
            </th>
            <th class="py-2 px-4 border border-gray-300">{{ product.variants[0].weight }}</th>
          </tr>
          <tr  v-if="product?.variants[0].quantityStock && ecommerce.visual?.displayBottomProductStock">
            <th class="py-2 px-4 border border-gray-300 w-40 font-medium">
              Estoque
            </th>
            <th class="py-2 px-4 border border-gray-300">{{ product.variants[0].quantityStock }}</th>
          </tr>
        </table>
      </div>
    </div>
    <!-- ./description -->

    <!-- Create review -->
    <div class="pb-8 flex flex-col gap-2 mx-auto px-4 sm:px-0" ref="reviewsListComponent" :style="{ maxWidth: ecommerce.visual?.productPageWidth }">
      <div class="flex flex-col sm:flex-row gap-4 sm:items-center justify-between mb-4">
        <div v-if="reviews?.length">
          <div class="flex items-center gap-2">
            <span class="font-bold text-[28px]">{{ averageRating }}</span>
            <RatingInput 
              :value="roundedAverageRating" 
              :name="'productRating'" 
              :id="'productRating'" 
              :disabled="true" 
            />
          </div>
          <h4 class="text-sm">{{ reviews?.length }} avaliações</h4>
        </div>
        <button class="border border-[#000] inline py-3 px-4 text-[12px] sm: text-md" @click="isReviewFormOpen = !isReviewFormOpen">Escrever avaliação</button>
      </div>
      <div :class="[
        'grid transition-[grid-template-rows] duration-300 ease-in-out',
        isReviewFormOpen ? 'grid-rows-[1fr]' : 'grid-rows-[0fr] opacity-0'
      ]">
        <div class="overflow-hidden">
          <h3 class="text-xl font-medium">Adicione uma avaliação</h3>
          <RatingInput v-model="reviewPayload.rating" :name="'reviewPayloadRating'" :id="'reviewPayloadRating'" />
          <form @submit.prevent="createReview" class="grid grid-cols-2 gap-4 md:w-[750px] lg:w-[950px]">
            <input type="text" placeholder="Nome" class="rounded-lg border border-gray-300 col-span-1" v-model="reviewPayload.name">
            <input type="email" placeholder="Email" class="rounded-lg border border-gray-300 col-span-1" v-model="reviewPayload.email">
            <textarea placeholder="Digite o que achou do produto" class="w-full h-[200px] p-4 rounded-lg border border-gray-300 col-span-2" v-model="reviewPayload.comment"></textarea>
            <div class="flex gap-4 items-center col-span-2 grid grid-cols-10">
              <label 
                :for="`review-media-${i}`" 
                v-for="i of [0,1,2,3,4]" 
                :key="i"
                class="border border-dashed p-4 h-36 w-full rounded-xl flex items-center justify-center flex-col border-2 border-zinc-300 gap-4 col-span-10 sm:col-span-5 lg:col-span-2"
                :style="{
                  backgroundImage: reviewPayload.media && reviewPayload.media[i] ? `url(${reviewPayload.media[i]})` : 'none',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center'
                }"
              >
                <template v-if="imageLoaders[i].isLoading">
                  <SpinnerLoader />
                  <p>{{ imageLoaders[i].progress }}%</p>
                </template>
                <template v-else>   
                  <template v-if="!(reviewPayload.media && reviewPayload.media[i])">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                      <path d="M9.5 10C10.6046 10 11.5 9.10457 11.5 8C11.5 6.89543 10.6046 6 9.5 6C8.39543 6 7.5 6.89543 7.5 8C7.5 9.10457 8.39543 10 9.5 10Z" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M13.5 2H9.5C4.5 2 2.5 4 2.5 9V15C2.5 20 4.5 22 9.5 22H15.5C20.5 22 22.5 20 22.5 15V10" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M16.25 5H21.75" stroke="#171717" stroke-width="1.5" stroke-linecap="round"/>
                      <path d="M19 7.75V2.25" stroke="#171717" stroke-width="1.5" stroke-linecap="round"/>
                      <path d="M3.17 18.9501L8.1 15.6401C8.89 15.1101 10.03 15.1701 10.74 15.7801L11.07 16.0701C11.85 16.7401 13.11 16.7401 13.89 16.0701L18.05 12.5001C18.83 11.8301 20.09 11.8301 20.87 12.5001L22.5 13.9001" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <p class="text-sm font-medium">Selecionar imagem</p>
                  </template>
                  <input type="file" hidden :id="`review-media-${i}`" @change="(e) => uploadReviewImage(e, i)">
                </template>
              </label>
            </div>
            <button class="bg-primary py-3 rounded-xl text-white col-span-2 flex items-center justify-center" :disabled="!reviewPayload.name || !reviewPayload.email || !reviewPayload.comment || !reviewPayload.rating">
              <template v-if="!isCreatingReview">Enviar</template>
              <div role="status" v-else>
                  <svg aria-hidden="true" class="w-6 h-6 animate-spin fill-zinc-500" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                  </svg>
                  <span class="sr-only">Loading...</span>
              </div>
            </button>
          </form>
        </div>
      </div>
    </div>
    <!-- Create review -->

    <!-- Product reviews -->
    <div class="mx-auto pb-16 px-4 sm:px-0" v-if="product" :style="{ maxWidth: ecommerce.visual?.productPageWidth }">
      <div class="columns-2 md:columns-2 lg:columns-3 xl:columns-4 2xl:columns-5 gap-4 space-y-4">
        <div 
          v-if="reviews && reviews?.length" 
          class="break-inside-avoid-column mb-4 border rounded-xl review w-full inline-block"
          v-for="review in reviews"
          :key="review.id"
          @click="selectedReview = (!!review.media.length ? review : undefined); isShowReviewModalOpen = !!review.media.length"
          :class="{ 'cursor-pointer': !!review.media.length }"
        >
          <div class="flex flex-col">
            <div class="w-full">
              <template v-for="(media, index) in review.media.filter(m => (m as ReviewMedia).isThumb)" :key="(media as ReviewMedia).id">
                <img 
                  v-if="index < 1" 
                  class="w-full h-[200px] object-cover rounded-tl-xl rounded-tr-xl" 
                  :src="(media as ReviewMedia).source" 
                  alt=""
                >
              </template>
            </div>
            <div class="p-4">
              <div class="flex items-center gap-2">
                <span class="font-semibold">{{ review.name }}</span>
              </div>
              <div class="flex gap-1 text-sm text-yellow-400">
                <RatingInput 
                  :value="review.rating!" 
                  :name="'reviewRating' + review.id" 
                  :id="'reviewRating' + review.id" 
                  :disabled="true" 
                />
              </div>
              <p class="text-xs text-gray-400 mb-4">
                Avaliado em {{ new Date(review.createdAt).toLocaleDateString('pt-BR') }}
              </p>
              <p class="text-sm">{{ review.comment }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Product reviews -->

    <ShowReviewModal :review="selectedReview" v-model:open="isShowReviewModalOpen" />

    <!-- related product -->
    <div class="pb-16 mx-auto" :style="{ maxWidth: ecommerce.visual?.productPageWidth }" v-if="relatedProducts.length">
      <h2 class="text-2xl font-medium text-gray-800 mb-6" :style="{ fontSize: ecommerce.visual?.productSectionTitleFontSize, fontFamily: ecommerce.visual?.productSectionTitleFontFamily, fontWeight: ecommerce.visual?.productSectionTitleFontWeight, color: ecommerce.visual?.colorProductSectionTitle }">
        Produtos Relacionados
      </h2>
      <ProductSection :products="relatedProducts" />
    </div>
    <!-- ./related product -->
  </PinkThemeLayout>
</template>

<script setup lang="ts">
import { useProduct } from '@/composables/useProduct';
import PinkThemeLayout from '../layouts/PinkThemeLayout.vue';
import { generateProductSlug } from '@/interfaces/products/Product';
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { useCategory } from '@/composables/useCategory';
import axiosClient from '@/config/axios';
import { Review, ReviewMedia } from '@/interfaces/ecommerce/Review';
import RatingInput from '@/components/inputs/RatingInput.vue';
import SpinnerLoader from '@/components/loaders/SpinnerLoader.vue';
import { Attribute, Variant } from '@/interfaces/products/Variant';
import { useCart } from '@/composables/useCart';
import ShowReviewModal from './components/ShowReviewModal.vue';
import { toast } from 'vue3-toastify'
import { useEcommerce } from '@/composables/useEcommerce';
import ProductSection from '@/components/sections/ProductSection.vue';

const { ecommerce } = useEcommerce()
const isShowReviewModalOpen = ref(false)
const selectedReview = ref<Review>()
const props = defineProps<{ slug: string }>()
const { products } = useProduct()
const { categories } = useCategory()
const product = computed(() => {
  const product = products.value.find((p) => generateProductSlug(p.title) === props.slug)
  return product
})
const { addIntoCart, isAddingProductIntoCart } = useCart()
const quantity = ref(1)
const category = computed(() => categories.value.find((c) => c.productIds.includes(product.value?.id ?? 0)))
const relatedProducts = computed(() => products.value.filter((p) => category.value?.productIds.includes(p.id) && p.id !== product.value?.id))
const selectedImage = ref<string>()
const reviewsListComponent = ref<HTMLElement>()
const reviewPayload = ref<Pick<Partial<Review>, 'comment' | 'name' | 'email' | 'rating' | 'media'>>({ media: [] })
const reviews = ref<Review[]>()
const isReviewsLoading = ref(false)
const isCreatingReview = ref(false)
const images = ref<File[]>([])
const isReviewFormOpen = ref(false)
const averageRating = computed(() => {
  if (!reviews.value?.length) return 0;

  const totalRating = reviews.value.reduce((sum, review) => sum + (review.rating || 0), 0);
  const averageRating = totalRating / reviews.value.length;

  return parseFloat(averageRating.toFixed(1)).toFixed(1);
});

const roundedAverageRating = computed(() => {
  if (!reviews.value?.length) return 0

  const totalRating = reviews.value.reduce((sum, review) => sum + (review.rating || 0), 0);
  const averageRating = totalRating / reviews.value.length;

  return Math.round(averageRating * 2) / 2;
})

function selectVariantByForm() {
  const selectedValues: { [key: string]: string } = {};
  
  for (const name of attributeNames.value) {
    const selectedInput = document.querySelector(`input[name="${name}"]:checked`) as HTMLInputElement;
    if (selectedInput) {
      selectedValues[name] = selectedInput.value;
    }
  }

  const variant = product.value?.variants.find((variant) => 
    variant.attributes?.every((attr) => selectedValues[attr.name] === attr.value)
  )
  
  selectedVariant.value = variant as Variant;
}
const imageLoaders = ref([
  { isLoading: false, progress: 0 },
  { isLoading: false, progress: 0 },
  { isLoading: false, progress: 0 },
  { isLoading: false, progress: 0 },
  { isLoading: false, progress: 0 }
])
const availableVariants = computed(() => {
  if (!product.value || !product.value.variants) return [];

  return product.value.variants.map((v) => {
    const updatedAttributes = v.attributes?.map((attr) => {
      const isAvailable = !selectedVariant.value || 
        selectedVariant.value.attributes[0].name === (v.attributes ?? [])[0].name && selectedVariant.value.attributes[0].value === (v.attributes ?? [])[0].value
      const checked = !!selectedVariant.value?.attributes.find((selectedAttr) => selectedAttr.name === attr.name && selectedAttr.value === attr.value)

      return {
        ...attr,
        isAvailable,
        checked
      };
    });

    return {
      ...v,
      attributes: updatedAttributes
    };
  });
});

const attributeNames = computed(() => {
  let names: string[] = []

  availableVariants.value?.forEach((v) => {
    v.attributes?.forEach((attr) => {
      if (!names?.includes(attr.name)) {
        names?.push(attr.name)
      }
    })
  })
  
  return names
})
const selectedVariant = ref<Variant>()

function getAttributesByName(name: string): Attribute[] {
  const attributes: Attribute[] = []

  availableVariants.value?.forEach((v) => {
    v.attributes?.forEach((attr) => {
      if (attr.name === name && !attributes.find((a) => a.value === attr.value)) {
        attributes.push(attr)
      }
    })
  })

  return attributes
}

function selectVariant(e: Event) {
  const attributeName = (e.target as HTMLInputElement).name
  const attributeValue = (e.target as HTMLInputElement).value

  availableVariants.value?.forEach((v) => {
    v.attributes?.forEach((attr) => {
      if (attr.name === attributeName && attr.value === attributeValue) {
        selectedVariant.value = v as Variant

        selectedImage.value = v.images![0]?.source ?? product.value?.images[0].source
      }
    })
  })
}

function uploadReviewImage(e: Event, index: number) {
  const reader = new FileReader()

  reader.onload = () => {
    imageLoaders.value[index].isLoading = false
    imageLoaders.value[index].progress = 100
    if (typeof reader.result === 'string' && reviewPayload.value.media) {
      reviewPayload.value.media[index] = reader.result
    }
  }

  reader.onprogress = (event: ProgressEvent<FileReader>) => {
    if (event.lengthComputable) {
      const percentComplete = Math.round((event.loaded / event.total) * 100)
      imageLoaders.value[index].progress = percentComplete
    }
  }

  const fileInput = (e.target as HTMLInputElement)

  if (fileInput && fileInput.files) {
    imageLoaders.value[index].isLoading = true
    imageLoaders.value[index].progress = 0
    reader.readAsDataURL(fileInput.files[0])
    images.value[index] = fileInput.files[0]
  }
}

function getReviewsByProductId(productId: number) {
  isReviewsLoading.value = true 

  axiosClient.get(`/reviews/${productId}`)
    .then((res) => {
      reviews.value = res.data
    })
    .finally(() => isReviewsLoading.value = false)
}

function createReview() {
  if (!isCreatingReview.value) {
    isCreatingReview.value = true;
  
    const formData = new FormData();
    
    formData.append('name', reviewPayload.value.name!);
    formData.append('email', reviewPayload.value.email!);
    formData.append('rating', String(reviewPayload.value.rating!));
    formData.append('comment', reviewPayload.value.comment!);
    formData.append('productId', String(product.value?.id));
  
    images.value.forEach((file, index) => {
      formData.append(`images`, file as unknown as File);
    });
  
    axiosClient.post('/reviews', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(() => {
        reviewPayload.value = { media: [] }
        images.value = []
        toast.success('Avaliação enviada com sucesso')
      })
      .finally(() => {
        isCreatingReview.value = false;
      });
  }
}

onMounted(() => {
  window.addEventListener("scroll", handleScroll)
})

onUnmounted(() => {
  window.removeEventListener("scroll", handleScroll)
})

watch(() => product.value, () => reviews.value = undefined)

 const handleScroll = (e: any) => {
  if (reviewsListComponent.value && reviewsListComponent.value?.getBoundingClientRect().bottom < window.innerHeight && !isReviewsLoading.value && !reviews.value) {
    getReviewsByProductId(product.value!.id)
  }
 }

</script>

<style lang="scss">
.review {
  .rate > label:before {
    font-size: 1.2rem !important;
  }
  fieldset {
    width: 122px !important
  }
}
button:disabled {
  background: #F1F1F1 !important;
  color: #6F6F6F !important;
  cursor: not-allowed !important;
}
.variant-input:disabled + label {
  border-style: dashed !important;
  opacity: 0.3;
  cursor: not-allowed
}
</style>