import axiosClient from "@/config/axios";
import { BannerPlacementEnum } from "@/enums/ecommerce/banners/BannerPlacementEnum";
import { EcommerceMediaCategoryEnum } from "@/enums/ecommerce/media/EcommerceMediaCategoryEnum";
import { Ecommerce } from "@/interfaces/ecommerce/Ecommerce";
import { ref } from "vue";
import { computed } from 'vue';

const ecommerce = ref<Ecommerce | Partial<Ecommerce>>(JSON.parse(localStorage.getItem('ecommerce') ?? '{}'))
const isEcommerceLoading = ref(false)

const topBanners = computed(() =>  ecommerce.value?.banners?.filter(banner => banner.placement === BannerPlacementEnum.TOP));
const bottomBanners = computed(() => ecommerce.value?.banners?.filter((banner) => banner.placement === BannerPlacementEnum.BOTTOM))
const logo = computed(() => ecommerce.value.media?.find(m => m.category === EcommerceMediaCategoryEnum.LOGO))

function addFavicon(href: string) {
  const existingFavicons = document.querySelectorAll('link[rel="icon"]');
  existingFavicons.forEach(favicon => favicon.remove());

  const link = document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'icon';
  link.href = href;
  document.head.appendChild(link);
}

function addMetaTag(property: string, content: string) {
  const existingMeta = document.querySelector(`meta[property='${property}']`);
  if (existingMeta) {
    existingMeta.setAttribute("content", content);
  } else {
    const meta = document.createElement("meta");
    meta.setAttribute("property", property);
    meta.setAttribute("content", content);
    document.head.appendChild(meta);
  }
}

function addPageTitle() {
  document.querySelector('title')?.remove()

  const titleEl = document.createElement('title')
  titleEl.innerText = ecommerce.value?.metaTitle ?? ''
  document.head.append(titleEl)
}

export function useEcommerce() {
  function loadEcommerce(refresh = false) {
    if (!ecommerce.value.id || refresh) {
      isEcommerceLoading.value = true 

      axiosClient.get('/details')
        .then((res) => {
          ecommerce.value = res.data
          localStorage.setItem('ecommerce', JSON.stringify(res.data))
        })
        .finally(() => isEcommerceLoading.value = false)
    }

    const favicon = ecommerce.value.media?.find(m => m.category === EcommerceMediaCategoryEnum.FAVICON)
    const urlShare = ecommerce.value.media?.find(m => m.category === EcommerceMediaCategoryEnum.SHARE)

    if (favicon) {
      addFavicon(favicon.source)
    }

    if (urlShare) {
      addMetaTag("og:image", urlShare.source);
    }

    if (ecommerce.value.metaDescription) {
      addMetaTag("description", ecommerce.value.metaDescription);
    }

    if (ecommerce.value.metaTitle) {
      addPageTitle()
    }
  }

  return {
    ecommerce,
    loadEcommerce,
    isEcommerceLoading,
    topBanners,
    bottomBanners,
    logo
  }
}