<template>
  <!-- navbar -->
  <nav v-if="ecommerce.visual?.displayNav" class="bg-gray-800 my-4 hidden sm:block" :style="{ fontFamily: ecommerce.visual?.navFontFamily, backgroundColor: ecommerce.visual?.colorMenu }">
    <div class="flex mx-auto" :style="{ maxWidth: ecommerce.visual?.navWidth, height: ecommerce.visual?.navHeight }">
      <div
        v-if="ecommerce.visual?.displayCategoriesAndCollectionsDropdown"
        class="px-4 bg-primary sm:px-6 sm:py-4 flex items-center cursor-pointer relative group"
        :style="{ backgroundColor: ecommerce.visual?.colorCategoriesAndCollectionsDropdownButtonBackground }"
      >
        <span class="text-white">
          <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none">
            <path d="M3 7H21" stroke="#fff" stroke-width="1.5" stroke-linecap="round"/>
            <path d="M3 12H21" stroke="#fff" stroke-width="1.5" stroke-linecap="round"/>
            <path d="M3 17H21" stroke="#fff" stroke-width="1.5" stroke-linecap="round"/>
          </svg>
        </span>
        <span class="capitalize ml-2 text-white hidden">Todas categorias</span>

        <!-- dropdown -->
        <div
          class="absolute left-0 top-full bg-white shadow-md py-3 divide-y divide-gray-300 divide-dashed opacity-0 group-hover:opacity-100 transition duration-300 invisible group-hover:visible w-64 z-10"
        >
          <RouterLink
            v-for="category in categories"
            :to="{ name: 'shop', query: { categoryId: category.id } }"
            class="flex items-center px-6 py-3 hover:bg-gray-100 transition"
          >
            <!-- <img
              src="assets/images/icons/sofa.svg"
              alt="sofa"
              class="w-5 h-5 object-contain"
            /> -->
            <span class="ml-6 text-gray-600 text-sm">{{ category.title }}</span>
          </RouterLink>
          <RouterLink
            v-for="collection in collections"
            :to="{ name: 'shop', query: { collectionId: collection.id } }"
            class="flex items-center px-6 py-3 hover:bg-gray-100 transition"
          >
            <!-- <img
              src="assets/images/icons/sofa.svg"
              alt="sofa"
              class="w-5 h-5 object-contain"
            /> -->
            <span class="ml-6 text-gray-600 text-sm">{{ collection.title }}</span>
          </RouterLink>
        </div>
      </div>

      <div class="flex items-center justify-between flex-grow pl-8 md:pl-12 py-5">
        <div class="flex items-center space-x-6 capitalize">
          <RouterLink 
          :to="{ name: 'home' }" 
          class="text-menu hover:text-menu-hover transition"
          :active-class="'text-menu-hover'"
            >Home</RouterLink
          >
          <RouterLink
            :to="{ name: 'shop' }"
            class="text-menu hover:text-menu-hover transition"
            :active-class="'text-menu-hover'"
            >Produtos</RouterLink
          >
          <MenuLink class="text-menu hover:text-menu-hover transition cursor-pointer" :menu="menu" v-for="menu in ecommerce?.menus ?? []" :key="menu.id"></MenuLink>
        </div>
      </div>
    </div>
  </nav>
  <!-- ./navbar -->
</template>

<script setup lang="ts">
import MenuLink from '@/components/menu/MenuLink.vue';
import { useCategory } from '@/composables/useCategory';
import { useCollection } from '@/composables/useCollection';
import { useEcommerce } from '@/composables/useEcommerce';
import { useMenu } from '@/composables/useMenu';
import { MenuTypeEnum } from '@/enums/ecommerce/menus/MenuTypeEnum';

const { categories } = useCategory()
const { collections } = useCollection()
const { ecommerce } = useEcommerce()
const { redirectMenu } = useMenu()
</script>

<style lang="scss" scoped>
a {
  color: v-bind('ecommerce.visual?.colorMenuText') !important;
  font-size: v-bind('ecommerce.visual?.navFontSize');
}
</style>