import { MenuTypeEnum } from "@/enums/ecommerce/menus/MenuTypeEnum";
import router from "@/router";
import { useCategory } from "./useCategory";
import { useCollection } from "./useCollection";
import { useEcommerce } from "./useEcommerce";
import { Menu } from "@/interfaces/ecommerce/Menu";

const { categories } = useCategory()
const { collections } = useCollection()
const { ecommerce } = useEcommerce()

export function useMenu() {
  function redirectMenu(e: Event, menu: Menu) {
    if (menu.type === MenuTypeEnum.PAGE) {
      e.preventDefault();
      const page = ecommerce.value.pages?.find(page => page.id === menu.typeId);

      router.push({ name: 'dynamic-page', params: { slug: page?.slug } });
    }

    if (menu.type === MenuTypeEnum.CATEGORY) {
      e.preventDefault();
      const category = categories.value.find(c => c.id === menu.typeId);
      router.push({ name: 'categories', params: { slug: category?.slug } });
    }

    if (menu.type === MenuTypeEnum.COLLECTION) {
      e.preventDefault();
      const collection = collections.value.find(c => c.id === menu.typeId);
      router.push({ name: 'collections', params: { slug: collection?.slug } });
    }
  }

  return {
    redirectMenu
  }
}