import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "max-w-screen-2xl mx-auto relative" }
const _hoisted_2 = { class: "py-2 px-4" }

import { useEcommerce } from '@/composables/useEcommerce';
import { ref, onMounted, onUnmounted } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'RotatingBanner',
  props: {
    messages: {}
  },
  setup(__props: any) {

const props = __props
const { ecommerce } = useEcommerce()

const currentIndex = ref<number>(0)
let intervalId: number | null = null

const rotateMessages = () => {
  intervalId = setInterval(() => {
    currentIndex.value = (currentIndex.value + 1) % props.messages.length
  }, 3000)
}

onMounted(() => {
  rotateMessages()
})

onUnmounted(() => {
  if (intervalId) {
    clearInterval(intervalId)
  }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "bg-green-500 text-white overflow-hidden",
    style: _normalizeStyle({ backgroundColor: _unref(ecommerce).visual?.colorRotatingBannerBackground, color: _unref(ecommerce).visual?.colorRotatingBannerText })
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_Transition, {
          mode: "out-in",
          "enter-active-class": "transition duration-500 ease-out",
          "enter-from-class": "transform translate-y-4 opacity-0",
          "enter-to-class": "transform translate-y-0 opacity-100",
          "leave-active-class": "transition duration-500 ease-in",
          "leave-from-class": "transform translate-y-0 opacity-100",
          "leave-to-class": "transform -translate-y-4 opacity-0"
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createElementBlock("div", {
              key: currentIndex.value,
              class: "flex items-center justify-center text-center font-medium"
            }, _toDisplayString(_ctx.messages[currentIndex.value]), 1))
          ]),
          _: 1
        })
      ])
    ])
  ], 4))
}
}

})