import { useCssVars as _useCssVars, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "relative"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "container" }
const _hoisted_4 = { class: "absolute bottom-[20%] mt-12" }
const _hoisted_5 = ["href"]
const _hoisted_6 = {
  key: 1,
  class: "relative"
}
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "container" }
const _hoisted_9 = { class: "absolute bottom-[20%] mt-12" }
const _hoisted_10 = ["href"]
const _hoisted_11 = ["src"]
const _hoisted_12 = ["href"]
const _hoisted_13 = ["src"]
const _hoisted_14 = ["href"]

import PinkThemeLayout from './layouts/PinkThemeLayout.vue';
import { useCollection } from '@/composables/useCollection';
import { useCategory } from '@/composables/useCategory';
import { useEcommerce } from '@/composables/useEcommerce';

import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import ProductSection from '@/components/sections/ProductSection.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'PinkThemeHomeView',
  setup(__props) {

_useCssVars(_ctx => ({
  "d37ed028": (_unref(ecommerce).visual?.colorPrimary),
  "86b3db5e": ((_unref(ecommerce).visual?.collectionsPerRow ?? 3) - 1),
  "86b3db5c": ((_unref(ecommerce).visual?.collectionsPerRow ?? 3) - 2),
  "27728630": (_unref(ecommerce).visual?.mobileCollectionsPerRow === 2 ? '1fr 1fr' : '1fr')
}))

const { ecommerce, topBanners, bottomBanners } = useEcommerce()
const { categories } = useCategory()
const { collections } = useCollection()

const SwiperAutoplay = Autoplay;
const SwiperPagination = Pagination;
const SwiperNavigation = Navigation;

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createBlock(PinkThemeLayout, null, {
    default: _withCtx(() => [
      (_unref(topBanners)?.length === 1)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("img", {
              class: "w-full",
              src: _unref(topBanners)[0].url,
              alt: "",
              srcset: ""
            }, null, 8, _hoisted_2),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                (_unref(topBanners)[0].buttonText)
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 0,
                      href: _unref(topBanners)[0].redirectUrl,
                      class: "bg-primary border border-primary text-white px-8 py-3 font-medium rounded-md hover:bg-transparent hover:text-primary"
                    }, _toDisplayString(_unref(topBanners)[0].buttonText), 9, _hoisted_5))
                  : _createCommentVNode("", true)
              ])
            ])
          ]))
        : (_unref(topBanners)?.length ?? 0 > 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode(_unref(Swiper), {
                modules: [_unref(SwiperAutoplay), _unref(SwiperPagination), _unref(SwiperNavigation)],
                autoplay: { delay: 5000, disableOnInteraction: false },
                pagination: { clickable: true },
                navigation: true,
                loop: true
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(topBanners), (banner) => {
                    return (_openBlock(), _createBlock(_unref(SwiperSlide), {
                      key: banner.id
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("img", {
                          class: "w-full",
                          src: banner.url,
                          alt: "",
                          srcset: ""
                        }, null, 8, _hoisted_7),
                        _createElementVNode("div", _hoisted_8, [
                          _createElementVNode("div", _hoisted_9, [
                            (banner.buttonText)
                              ? (_openBlock(), _createElementBlock("a", {
                                  key: 0,
                                  href: banner.redirectUrl,
                                  class: "bg-primary border border-primary text-white px-8 py-3 font-medium rounded-md hover:bg-transparent hover:text-primary"
                                }, _toDisplayString(banner.buttonText), 9, _hoisted_10))
                              : _createCommentVNode("", true)
                          ])
                        ])
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modules"])
            ]))
          : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: "py-16 mx-auto",
        style: _normalizeStyle({ maxWidth: _unref(ecommerce).visual?.collectionSectionWidth })
      }, [
        (_unref(ecommerce).visual?.displayCollectionSectionTitle)
          ? (_openBlock(), _createElementBlock("h2", {
              key: 0,
              class: "text-2xl font-medium text-gray-800 uppercase mb-6",
              style: _normalizeStyle({ fontSize: _unref(ecommerce).visual?.collectionSectionTitleFontSize, fontFamily: _unref(ecommerce).visual?.collectionSectionTitleFontFamily, fontWeight: _unref(ecommerce).visual?.collectionSectionTitleFontWeight, color: _unref(ecommerce).visual?.colorCollectionSectionTitle })
            }, " coleções ", 4))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass(["grid grid-cols-2 gap-3 collection-section", [`grid-cols-${_unref(ecommerce).visual?.collectionsPerRow ?? 3}`]])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(collections), (collection) => {
            return (_openBlock(), _createBlock(_component_RouterLink, {
              to: { name: 'collections', params: { slug: collection.slug } },
              class: "relative rounded-sm overflow-hidden group col-span-1",
              style: _normalizeStyle({ maxWidth: _unref(ecommerce).visual?.collectionWidth })
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  class: "relative h-auto",
                  style: _normalizeStyle({ maxWidth: _unref(ecommerce).visual?.collectionWidth })
                }, [
                  _createElementVNode("img", {
                    src: collection.media.length ? collection.media[0].source : 'assets/images/no-image.png',
                    alt: "category 1",
                    class: "w-full h-full object-fit",
                    onError: _cache[0] || (_cache[0] = ($event: any) => (($event.target as HTMLImageElement).src = '/assets/images/no-image.png')),
                    style: _normalizeStyle({ borderRadius: _unref(ecommerce).visual?.collectionBorderRadius, maxWidth: _unref(ecommerce).visual?.collectionWidth, height: '100%' })
                  }, null, 44, _hoisted_11),
                  (_unref(ecommerce).visual?.collectionTitlePlacement === 'image-center')
                    ? (_openBlock(), _createElementBlock("a", {
                        key: 0,
                        href: "#",
                        class: _normalizeClass(["absolute inset-0 flex items-center justify-center text-xl text-white font-roboto font-medium group-hover:bg-opacity-60 transition p-4", [_unref(ecommerce).visual?.displayCollectionOverlay ? 'bg-black bg-opacity-40' : '']]),
                        style: _normalizeStyle({ fontFamily: _unref(ecommerce).visual?.collectionTitleFontFamily, color: _unref(ecommerce).visual?.colorCollectionTitle, fontSize: _unref(ecommerce).visual?.collectionTitleFontSize, fontWeight: _unref(ecommerce).visual?.collectionTitleFontWeight })
                      }, _toDisplayString(collection.title), 7))
                    : _createCommentVNode("", true)
                ], 4),
                (_unref(ecommerce).visual?.collectionTitlePlacement === 'bellow')
                  ? (_openBlock(), _createElementBlock("h5", {
                      key: 0,
                      class: "text-center pt-4",
                      style: _normalizeStyle({ fontFamily: _unref(ecommerce).visual?.collectionTitleFontFamily, color: _unref(ecommerce).visual?.colorCollectionTitle, fontSize: _unref(ecommerce).visual?.collectionTitleFontSize, fontWeight: _unref(ecommerce).visual?.collectionTitleFontWeight })
                    }, _toDisplayString(collection.title), 5))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1032, ["to", "style"]))
          }), 256))
        ], 2)
      ], 4),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(categories), (category) => {
        return (_openBlock(), _createElementBlock(_Fragment, {
          key: category.id
        }, [
          (category.products?.length)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "pb-16 pt-16 mx-auto category-section",
                style: _normalizeStyle({ maxWidth: _unref(ecommerce).visual?.productSectionWidth })
              }, [
                (_unref(ecommerce).visual?.displayProductSectionTitle)
                  ? (_openBlock(), _createElementBlock("h2", {
                      key: 0,
                      class: "text-2xl font-medium text-gray-800 mb-6",
                      style: _normalizeStyle({ fontSize: _unref(ecommerce).visual?.productSectionTitleFontSize, fontFamily: _unref(ecommerce).visual?.productSectionTitleFontFamily, fontWeight: _unref(ecommerce).visual?.productSectionTitleFontWeight, color: _unref(ecommerce).visual?.colorProductSectionTitle })
                    }, _toDisplayString(category.title), 5))
                  : _createCommentVNode("", true),
                _createVNode(ProductSection, {
                  products: category.products
                }, null, 8, ["products"])
              ], 4))
            : _createCommentVNode("", true)
        ], 64))
      }), 128)),
      _createElementVNode("div", {
        class: "mx-auto flex flex-col gap-8 bottom-banner-section py-16",
        style: _normalizeStyle({ maxWidth: _unref(ecommerce).visual?.bottomBannerWidth })
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(bottomBanners), (banner) => {
          return (_openBlock(), _createElementBlock("a", {
            class: "relative",
            href: banner.redirectUrl
          }, [
            _createElementVNode("img", {
              src: banner.url,
              alt: "ads",
              class: "w-full"
            }, null, 8, _hoisted_13),
            (banner.buttonText)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  href: banner.redirectUrl,
                  class: "bg-primary border border-primary text-white px-8 py-3 font-medium rounded-md hover:bg-transparent hover:text-primary absolute bottom-[25%] left-[10%]"
                }, _toDisplayString(banner.buttonText), 9, _hoisted_14))
              : _createCommentVNode("", true)
          ], 8, _hoisted_12))
        }), 256))
      ], 4)
    ]),
    _: 1
  }))
}
}

})