import { useCssVars as _useCssVars, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "absolute left-0 top-full bg-white shadow-md py-3 divide-y divide-gray-300 divide-dashed opacity-0 group-hover:opacity-100 transition duration-300 invisible group-hover:visible w-64 z-10" }
const _hoisted_2 = { class: "ml-6 text-gray-600 text-sm" }
const _hoisted_3 = { class: "ml-6 text-gray-600 text-sm" }
const _hoisted_4 = { class: "flex items-center justify-between flex-grow pl-8 md:pl-12 py-5" }
const _hoisted_5 = { class: "flex items-center space-x-6 capitalize" }

import MenuLink from '@/components/menu/MenuLink.vue';
import { useCategory } from '@/composables/useCategory';
import { useCollection } from '@/composables/useCollection';
import { useEcommerce } from '@/composables/useEcommerce';
import { useMenu } from '@/composables/useMenu';
import { MenuTypeEnum } from '@/enums/ecommerce/menus/MenuTypeEnum';


export default /*@__PURE__*/_defineComponent({
  __name: 'PinkThemeNav',
  setup(__props) {

_useCssVars(_ctx => ({
  "b9f4986c": (_unref(ecommerce).visual?.colorMenuText),
  "65f4520e": (_unref(ecommerce).visual?.navFontSize)
}))

const { categories } = useCategory()
const { collections } = useCollection()
const { ecommerce } = useEcommerce()
const { redirectMenu } = useMenu()

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_unref(ecommerce).visual?.displayNav)
    ? (_openBlock(), _createElementBlock("nav", {
        key: 0,
        class: "bg-gray-800 my-4 hidden sm:block",
        style: _normalizeStyle({ fontFamily: _unref(ecommerce).visual?.navFontFamily, backgroundColor: _unref(ecommerce).visual?.colorMenu })
      }, [
        _createElementVNode("div", {
          class: "flex mx-auto",
          style: _normalizeStyle({ maxWidth: _unref(ecommerce).visual?.navWidth, height: _unref(ecommerce).visual?.navHeight })
        }, [
          (_unref(ecommerce).visual?.displayCategoriesAndCollectionsDropdown)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "px-4 bg-primary sm:px-6 sm:py-4 flex items-center cursor-pointer relative group",
                style: _normalizeStyle({ backgroundColor: _unref(ecommerce).visual?.colorCategoriesAndCollectionsDropdownButtonBackground })
              }, [
                _cache[0] || (_cache[0] = _createStaticVNode("<span class=\"text-white\" data-v-61c3cc07><svg xmlns=\"http://www.w3.org/2000/svg\" width=\"28\" height=\"28\" viewBox=\"0 0 24 24\" fill=\"none\" data-v-61c3cc07><path d=\"M3 7H21\" stroke=\"#fff\" stroke-width=\"1.5\" stroke-linecap=\"round\" data-v-61c3cc07></path><path d=\"M3 12H21\" stroke=\"#fff\" stroke-width=\"1.5\" stroke-linecap=\"round\" data-v-61c3cc07></path><path d=\"M3 17H21\" stroke=\"#fff\" stroke-width=\"1.5\" stroke-linecap=\"round\" data-v-61c3cc07></path></svg></span><span class=\"capitalize ml-2 text-white hidden\" data-v-61c3cc07>Todas categorias</span>", 2)),
                _createElementVNode("div", _hoisted_1, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(categories), (category) => {
                    return (_openBlock(), _createBlock(_component_RouterLink, {
                      to: { name: 'shop', query: { categoryId: category.id } },
                      class: "flex items-center px-6 py-3 hover:bg-gray-100 transition"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_2, _toDisplayString(category.title), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"]))
                  }), 256)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(collections), (collection) => {
                    return (_openBlock(), _createBlock(_component_RouterLink, {
                      to: { name: 'shop', query: { collectionId: collection.id } },
                      class: "flex items-center px-6 py-3 hover:bg-gray-100 transition"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_3, _toDisplayString(collection.title), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"]))
                  }), 256))
                ])
              ], 4))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_RouterLink, {
                to: { name: 'home' },
                class: "text-menu hover:text-menu-hover transition",
                "active-class": 'text-menu-hover'
              }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode("Home")
                ])),
                _: 1
              }),
              _createVNode(_component_RouterLink, {
                to: { name: 'shop' },
                class: "text-menu hover:text-menu-hover transition",
                "active-class": 'text-menu-hover'
              }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode("Produtos")
                ])),
                _: 1
              }),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(ecommerce)?.menus ?? [], (menu) => {
                return (_openBlock(), _createBlock(MenuLink, {
                  class: "text-menu hover:text-menu-hover transition cursor-pointer",
                  menu: menu,
                  key: menu.id
                }, null, 8, ["menu"]))
              }), 128))
            ])
          ])
        ], 4)
      ], 4))
    : _createCommentVNode("", true)
}
}

})