<template>
  <PinkThemeLayout>
    <div class="container pt-4 pb-16" v-html="page?.content"></div>
  </PinkThemeLayout>
</template>

<script setup lang="ts">
import { useEcommerce } from '@/composables/useEcommerce';
import router from '@/router';
import { computed } from 'vue';
import PinkThemeLayout from '../layouts/PinkThemeLayout.vue';

const props = defineProps<{ slug: string }>()
const { ecommerce } = useEcommerce()

const page = computed(() => {
  const page = ecommerce.value.pages?.find(page => page.slug === props.slug)

  if (!page) {
    router.push({ name: 'not-found' })
  }

  return page
})
</script>