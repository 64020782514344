<template>
  <div class="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
    <div class="bg-white rounded-lg p-8 w-full max-w-md mx-4 shadow-xl">
      <h2 class="text-2xl font-bold text-gray-900 mb-6 text-center">Loja em Teste</h2>
      
      <form @submit.prevent="handleSubmit" class="space-y-6">
        <div>
          <label for="password" class="block text-sm font-medium text-gray-700 mb-2">
            Digite a senha da loja
          </label>
          <div class="relative">
            <input
              :type="showPassword ? 'text' : 'password'"
              id="password"
              v-model="password"
              class="block w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-colors"
              :class="{ 'border-red-500': error }"
              placeholder="Digite a senha"
              required
            />
            <button
              type="button"
              @click="showPassword = !showPassword"
              class="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700"
            >
              <EyeIcon v-if="!showPassword" class="h-5 w-5" />
              <EyeOffIcon v-else class="h-5 w-5" />
            </button>
          </div>
          <p v-if="error" class="mt-2 text-sm text-red-600">{{ error }}</p>
        </div>

        <button
          type="submit"
          class="w-full bg-blue-600 text-white py-3 px-4 rounded-lg font-medium hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors"
          :disabled="isLoading"
        >
          <span v-if="isLoading" class="flex items-center justify-center">
            <LoaderIcon class="animate-spin h-5 w-5 mr-2" />
            Verificando...
          </span>
          <span v-else>Acessar Loja</span>
        </button>
      </form>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useEcommerce } from '@/composables/useEcommerce'
import router from '@/router'
import { ref } from 'vue'

const password = ref('')
const showPassword = ref(false)
const error = ref('')
const isLoading = ref(false)
const { ecommerce } = useEcommerce()

const handleSubmit = async () => {
  try {
    error.value = ''
    isLoading.value = true
    
    // Simulating API call to validate store password
    await new Promise(resolve => setTimeout(resolve, 1000))
    
    if (password.value !== ecommerce.value.password) { // Replace with actual validation
      throw new Error('Senha incorreta. Por favor, tente novamente.')
    }

    localStorage.setItem('password', password.value)
    
    router.push({ name: 'home' })
    
  } catch (err) {
    error.value = err instanceof Error ? err.message : 'Ocorreu um erro. Tente novamente.'
  } finally {
    isLoading.value = false
  }
}
</script>