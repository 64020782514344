<template>
  <PinkThemeLayout>
    <!-- breadcrumb -->
    <!-- <div class="container py-4 flex items-center gap-3">
      <a href="../index.html" class="text-primary text-base">
        <i class="fa-solid fa-house"></i>
      </a>
      <span class="text-sm text-gray-400">
        <i class="fa-solid fa-chevron-right"></i>
      </span>
      <p class="text-gray-600 font-medium">Sacola</p>
    </div> -->
    <!-- ./breadcrumb -->

    <!-- wrapper -->
    <div class="container grid grid-cols-12 items-start pb-16 pt-4 gap-6 min-h-[55vh]">
      <div class="col-span-12 sm:col-span-10 lg:col-span-6 border border-gray-200 p-4 rounded">
        <div class="flex justify-between items-center mb-4">
          <h4 class="text-gray-800 text-lg font-medium uppercase">
            resumo da compra
          </h4>
          <button class="underline text-gray-400" @click="clearCart">
            Limpar
          </button>
        </div>
        <div class="pb-16" v-if="cartItems.length">
          <div class="space-y-2 pb-4" v-for="item in cartItems">
            <div class="flex justify-between items-center flex-wrap relative">
              <button class="absolute right-0 top-2" @click="removeFromCart(item.uuid)">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none">
                  <path d="M21 5.97998C17.67 5.64998 14.32 5.47998 10.98 5.47998C9 5.47998 7.02 5.57998 5.04 5.77998L3 5.97998" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M18.8499 9.13989L18.1999 19.2099C18.0899 20.7799 17.9999 21.9999 15.2099 21.9999H8.7899C5.9999 21.9999 5.9099 20.7799 5.7999 19.2099L5.1499 9.13989" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M10.3301 16.5H13.6601" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M9.5 12.5H14.5" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
              <div class="w-28">
                <img
                  :src="item.images[0]?.source ?? item.productImage ?? '/assets/images/no-image.png'"
                  alt="product 6"
                  class="w-full object-fit"
                  style="height: 6rem"
                  @error="($event.target as HTMLImageElement).src = '/assets/images/no-image.png'"
                />
              </div>
              <div>
                <h5 class="text-gray-800 font-medium">{{ item.title }}</h5>
                <p class="text-sm text-gray-600" v-for="attribute in item.attributes">{{ attribute.name }}: {{ attribute.value }}</p>
              </div>
              <div>
                <div
                  class="flex border border-gray-300 text-gray-600 divide-x divide-gray-300 w-max"
                >
                  <button
                    class="h-8 w-8 text-xl flex items-center justify-center cursor-pointer select-none"
                    @click="item.quantity! > 1 ? addIntoCart(item.uuid, -1) : removeFromCart(item.uuid)"
                  >
                    -
                  </button>
                  <div class="h-8 w-8 text-base flex items-center justify-center">
                    {{ item.quantity }}
                  </div>
                  <button
                    class="h-8 w-8 text-xl flex items-center justify-center cursor-pointer select-none"
                    @click="addIntoCart(item.uuid, 1)"
                  >
                    +
                  </button>
                </div>
              </div>
              <p class="text-gray-600">x{{ item.quantity }}</p>
              <p class="text-gray-800 font-medium">{{ (item.promotionalPrice ? item.promotionalPrice : item.price).toLocaleString('pt-BR', { currency: 'BRL', style: 'currency' }) }}</p>
            </div>
          </div>
        </div>
        <div class="flex flex-col items-center col-span-12 pt-8 pb-12 gap-2" v-else>
          <svg xmlns="http://www.w3.org/2000/svg" width="124" height="124" viewBox="0 0 24 24" fill="none">
            <path d="M19.9602 8.96002C19.2902 8.22002 18.2802 7.79002 16.8802 7.64002V6.88002C16.8802 5.51002 16.3002 4.19002 15.2802 3.27002C14.2502 2.33002 12.9102 1.89002 11.5202 2.02002C9.13023 2.25002 7.12023 4.56002 7.12023 7.06002V7.64002C5.72023 7.79002 4.71023 8.22002 4.04023 8.96002C3.07023 10.04 3.10023 11.48 3.21023 12.48L3.91023 18.05C4.12023 20 4.91023 22 9.21023 22H14.7902C19.0902 22 19.8802 20 20.0902 18.06L20.7902 12.47C20.9002 11.48 20.9202 10.04 19.9602 8.96002ZM11.6602 3.41002C12.6602 3.32002 13.6102 3.63002 14.3502 4.30002C15.0802 4.96002 15.4902 5.90002 15.4902 6.88002V7.58002H8.51023V7.06002C8.51023 5.28002 9.98023 3.57002 11.6602 3.41002ZM8.42023 13.15H8.41023C7.86023 13.15 7.41023 12.7 7.41023 12.15C7.41023 11.6 7.86023 11.15 8.41023 11.15C8.97023 11.15 9.42023 11.6 9.42023 12.15C9.42023 12.7 8.97023 13.15 8.42023 13.15ZM15.4202 13.15H15.4102C14.8602 13.15 14.4102 12.7 14.4102 12.15C14.4102 11.6 14.8602 11.15 15.4102 11.15C15.9702 11.15 16.4202 11.6 16.4202 12.15C16.4202 12.7 15.9702 13.15 15.4202 13.15Z" class="fill-zinc-400" fill="#171717"/>
          </svg>
          <p class="text-lg text-zinc-500">Sem produtos no carrinho</p>
        </div>

        <template v-if="cartItems.length">
          <div
            class="flex justify-between border-b border-gray-200 mt-1 text-gray-800 font-medium py-3 uppercas"
          >
            <!-- <p>subtotal</p>
            <p>{{ cartTotalAmount.toLocaleString('pt-BR', { currency: 'BRL', style: 'currency' }) }}</p> -->
          </div>
  
          <!-- <div
            class="flex justify-between border-b border-gray-200 mt-1 text-gray-800 font-medium py-3 uppercas"
          >
            <p>Frete</p>
            <p>Grátis</p>
          </div> -->
  
          <div
            class="flex justify-between text-gray-800 font-medium py-3 uppercas"
          >
            <p class="font-semibold">Total</p>
            <p>{{ cartTotalAmount.toLocaleString('pt-BR', { currency: 'BRL', style: 'currency' }) }}</p>
          </div>
  
          <!-- <div class="flex items-center mb-4 mt-2">
            <input
              type="checkbox"
              name="aggrement"
              id="aggrement"
              class="text-primary focus:ring-0 rounded-sm cursor-pointer w-3 h-3"
            />
            <label
              for="aggrement"
              class="text-gray-600 ml-3 cursor-pointer text-sm"
              >Eu concordo com os
              <a href="#" class="text-primary">termos & condições</a></label
            >
          </div> -->

        </template>
        <button
          href="#"
          class="block w-full py-3 px-4 text-center text-white bg-primary border border-primary rounded-md hover:bg-transparent hover:text-primary transition font-medium place-order"
          @click="redirectionToCheckout"
          :disabled="!cart.length"
          >Fazer pedido</button
        >

      </div>
    </div>
    <!-- ./wrapper -->
  </PinkThemeLayout>
</template>

<script setup lang="ts">
import { useCart } from "@/composables/useCart";
import PinkThemeLayout from "../layouts/PinkThemeLayout.vue";
import { computed } from "vue";
import { useProduct } from "@/composables/useProduct";
import { Variant } from "@/interfaces/products/Variant";

const { products } = useProduct()
const { cart, addIntoCart, removeFromCart, clearCart } = useCart()

const cartItems = computed<Variant[]>(() => cart.value.flatMap((item) => {
  return products.value.flatMap((p) => 
    p.variants.filter((v) => v.uuid === item.uuid).map((v) => ({ ...v, quantity: item.quantity, productImage: (p.images ?? [])[0]?.source }))
  ) as Variant[]
}))
const cartTotalAmount = computed(() => 
  cartItems.value.reduce((acc, item) => 
    acc + (item.quantity! * (item.promotionalPrice ?? item.price)), 0
  )
)

function redirectionToCheckout() {
  const baseUrl = process.env.VUE_APP_CHECKOUT_BASE_URL; 
  
  const productIds = cartItems.value.map((item) => `productId[]=${encodeURIComponent(item.uuid)}`).join('&');
  const quantities = cartItems.value.map((item) => `qty[]=${encodeURIComponent(item.quantity!)}`).join('&');
  
  const queryString = `${productIds}&${quantities}`;
  const finalUrl = `${baseUrl}?${queryString}`;

  window.location.href = finalUrl;
}
</script>

<style lang="scss" scoped>
button.place-order:disabled{
  border: unset !important;
  cursor: not-allowed !important;
}
</style>